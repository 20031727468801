import {
  getOrdersFromServer,
  addBill,
  deleteOrder,
  freezOrder,
  deleteFreezOrder,
} from './requests';
import { handleRequestError, setState } from 'utils';
import { requests } from 'modules/main/controllers';

import moment from 'moment';
import { notification } from 'antd';
export const model = {
  state: {
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    orders: [],
    ordersForExcel: [],
    search: {},
    loading: false,
    progressShow: false,
    allOrders: false,
  },
  reducers: {
    resetState: () => ({ orders: [], search: {} }),
    setOrders(state, orders) {
      return { ...state, orders };
    },
    setState,
  },

  effects: (dispatch) => ({
    async getOrdersFromServer(params = {}, rootState = {}) {
      const userData = rootState?.users?.userData || {};
      const pagination = rootState?.adminOrders?.pagination || {
        pageSize: 10,
        current: 1,
      };
      const allOrders = rootState?.adminOrders?.allOrders || false;
      const { current, pageSize } = pagination;
      const { role = '', _id = '', firstName = '', lastName = '' } = userData;
      const search = rootState?.adminOrders?.search || {};
      const client = {};
      const manager_id = search?.manager_id || '';
      client.student_name = search?.student_name || '';
      client.payer_name = search?.payer_name || '';
      client.contact_phone = search?.contact_phone || '';
      client.email = search?.email || '';
      const date = search?.create_date?.split('-') || '';
      const dD = date[0] || '';
      const dM = date[1] || '';
      const dY = date[2] || '';
      const fromDate = search?.create_date_range?.[0]
        ? moment(search?.create_date_range?.[0] || '')?.format('YYYY-MM-DD')
        : '';
      const toDate = search?.create_date_range?.[1]
        ? moment(search?.create_date_range?.[1] || '')?.format('YYYY-MM-DD')
        : '';
      let requestBody = {
        page: current,
        limit: allOrders ? 0 : pageSize,
        sort: {},
        ...params,
      };
      requestBody.client = client;
      requestBody.order_id = +search?.order_id || '';
      requestBody.paid = search?.paid || '';
      requestBody.delete1C = search?.delete1C || '';
      requestBody.programName = search?.program_name || '';
      requestBody.manager = { id: manager_id };
      requestBody.fromDate = fromDate;
      requestBody.toDate = toDate;
      requestBody.createDate = date
        ? {
            d: dD,
            m: dM,
            y: dY,
          }
        : {};
      if (manager_id === 'showAll' || manager_id === '') {
        requestBody.manager = '';
      }

      try {
        dispatch.adminOrders.setState({ loading: true });
        const result = await getOrdersFromServer(requestBody);

        dispatch.adminOrders.setOrders(result?.orders);
        // console.log('result', result);
        dispatch.adminOrders.setState({
          pagination: { current, pageSize, total: result?.total || 0 },
        });
        dispatch.adminOrders.setState({ loading: false });
        return result;
      } catch (error) {
        dispatch.adminOrders.setState({ loading: false });
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminOrders.getOrdersFromServer,
          requestBody,
        });
      }
    },
    async getOrdersForExcel(params = {}, rootState = {}) {
      const userData = rootState?.users?.userData || {};
      const allOrders = rootState?.adminOrders?.allOrders || false;
      const { role = '', _id = '', firstName = '', lastName = '' } = userData;
      const search = rootState?.adminOrders?.search || {};
      const client = {};
      const manager_id = search?.manager_id || '';
      client.student_name = search?.student_name || '';
      client.payer_name = search?.payer_name || '';
      client.contact_phone = search?.contact_phone || '';
      client.email = search?.email || '';
      const date = search?.create_date?.split('-') || '';
      const dD = date[0] || '';
      const dM = date[1] || '';
      const dY = date[2] || '';
      const fromDate = search?.create_date_range?.[0]
        ? moment(search?.create_date_range?.[0] || '')?.format('YYYY-MM-DD')
        : '';
      const toDate = search?.create_date_range?.[1]
        ? moment(search?.create_date_range?.[1] || '')?.format('YYYY-MM-DD')
        : '';
      let requestBody = {
        page: '',
        limit: 0,
        sort: {},
        ...params,
      };
      requestBody.client = client;
      requestBody.order_id = search?.order_id || '';
      requestBody.paid = search?.paid || '';
      requestBody.delete1C = search?.delete1C || '';
      requestBody.programName = search?.program_name || '';
      requestBody.manager = { id: manager_id };
      requestBody.fromDate = fromDate;
      requestBody.toDate = toDate;
      requestBody.createDate = date
        ? {
            d: dD,
            m: dM,
            y: dY,
          }
        : {};
      if (manager_id === 'showAll' || manager_id === '') {
        requestBody.manager = '';
      }

      try {
        if (fromDate && toDate) {
          dispatch.adminOrders.setState({ loading: true });
          const result = await getOrdersFromServer(requestBody);
          dispatch.adminOrders.setState({ ordersForExcel: result?.orders });
          // console.log('result', result);
          dispatch.adminOrders.setState({ loading: false });
          return true;
        } else {
          notification.error({
            message: "Обов'язково оберіть дату у фільтрі",
            duration: 5,
          });
          return null;
        }
      } catch (error) {
        dispatch.adminOrders.setState({ loading: false });
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminOrders.getOrdersForExcel,
          requestBody,
        });
      }
    },

    async addBill(paylod, rootState = {}) {
      const userData = rootState?.users?.userData || {};
      const date = Date.now();
      const info = { manager: userData, type: 'add', date: date };
      try {
        dispatch.adminOrders.setState({ loading: true });
        const result = await addBill({ ...paylod, info: info });
        dispatch.adminOrders.setState({ loading: false });

        return result;
      } catch (error) {
        dispatch.adminOrders.setState({ loading: false });
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminOrders.addBill,
          paylod,
        });
      }
    },
    async deleteOrder(paylod) {
      try {
        dispatch.adminOrders.setState({ loading: true });
        const result = await deleteOrder({ ...paylod });
        dispatch.adminOrders.setState({ loading: false });
        return result;
      } catch (error) {
        dispatch.adminOrders.setState({ loading: false });
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminOrders.deleteOrder,
          paylod,
        });
      }
    },
    async freezOrder(paylod) {
      try {
        dispatch.adminOrders.setState({ loading: true });
        const result = await freezOrder({ ...paylod });
        dispatch.adminOrders.setState({ loading: false });
        return result;
      } catch (error) {
        dispatch.adminOrders.setState({ loading: false });
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminOrders.freezOrder,
          paylod,
        });
      }
    },
    async deleteFreezOrder(paylod) {
      try {
        dispatch.adminOrders.setState({ loading: true });
        const result = await deleteFreezOrder({ ...paylod });
        dispatch.adminOrders.setState({ loading: false });
        return result;
      } catch (error) {
        dispatch.adminOrders.setState({ loading: false });
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminOrders.deleteFreezOrder,
          paylod,
        });
      }
    },
  }),
};
