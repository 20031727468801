// Libraries
import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 120px;
`;

export const WrapBorder = styled.div`
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
`;


export const Row = styled.div`
  display: flex;
  column-gap: 20px;
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

export const Th = styled.th`
  font-size: 16px;
`;

export const Tr = styled.tr`
  display: flex;
  column-gap: 20px;
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Td = styled.td`
  font-size: 16px;
  width: ${({ width }) => width};
  text-align: ${({ textAlign }) => textAlign};
`;

export const Input = styled.input`
  font-size: 16px;
  font-weight: 600;
  width: 5rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    transition: 0.4s;
    outline-color: #61b5ff;
  }
`;

export const CurrencyName = styled.p`
  display: inline;
  color: ${({ grayed }) => (grayed ? '#ddd' : 'black')};
  :first-child {
    margin-right: 4px;
  }
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.075em;
  font-size: 22px;
`;

export const Button = styled.button`
  display: block;
  background-color: #61b5ff;
  color: #fff;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  border: none;
  margin: 30px auto 0;
  border-radius: 5px;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  user-select: none;
  outline: none;

  :hover {
    cursor: pointer;
    background-color: #168ef8;

  }

  :active {
    transition: 0.3s;
    box-shadow: none;
  }

  :focus {
    outline: none;
  }
`;

export const ButtonPrivat = styled(Button)`
  background-color: #5a9310;

  :hover {
    background-color: #416b0b;
  }
`;