import usersController from '../controllers/requests';
import { handleRequestError } from 'utils';
import initialState from './initialState';

export const model = {
  state: initialState,
  reducers: {
    resetState: () => initialState,
    setUsers(state, payload) {
      return {
        ...state,
        users: payload,
      };
    },
    setModalData(state, payload) {
      return {
        ...state,
        modalData: payload,
      };
    },
    addUser(state, newUser) {
      return {
        ...state,
        users: [...state.users, newUser],
      };
    },
    editUser(state, edUser) {
      return {
        ...state,
        users: state.users.map((r) =>
          String(edUser._id) === String(r._id) ? edUser : r
        ),
      };
    },
    removeUser(state, reUser) {
      return {
        ...state,
        users: state.users.filter((r) => reUser._id !== r._id),
      };
    },
    setModal(state, payload) {
      return {
        ...state,
        modal: payload,
      };
    },
    setCurrentRecord(state, payload) {
      return {
        ...state,
        currentRecord: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getAllUsers(params, rootState) {
      try {
        const result = await usersController.getAllUsers(params);

        if (!Array.isArray(result)) throw result;

        dispatch.adminUsers.setUsers(result);
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminUsers.getAllUsers,
          params,
        });
      }
    },
    async addUserToServer(params, rootState) {
      try {
        const result = await usersController.addUserToServer(params);

        await dispatch.adminUsers.addUser(params);
        await dispatch.adminUsers.getAllUsers({});
        return result;
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminUsers.addUserToServer,
          params,
        });
      }
    },
    async editUserToServer(params = {}, rootState) {
      const currentRecord = rootState?.adminUsers?.currentRecord || {};
      const requestBody = { ...currentRecord, ...params };

      try {
        await usersController.editUserToServer(requestBody);

        dispatch.adminUsers.editUser(params);
        dispatch.adminUsers.getAllUsers({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminUsers.editUserToServer,
          params: requestBody,
        });
      }
    },
    async removeUserFromServer(_id = '', rootState) {
      try {
        await usersController.removeUserFromServer(_id);

        dispatch.adminUsers.getAllUsers({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminUsers.removeUserFromServer,
          params: _id,
        });
      }
    },
  }),
};

export default model;
