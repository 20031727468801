import moment from 'moment';
export const trasformData = (order = {}, bill = {}) => {
  const text_m = {
    '01': 'січня',
    '02': 'лютого',
    '03': 'березня',
    '04': 'квітня',
    '05': 'травня',
    '06': 'червня',
    '07': 'липня',
    '08': 'серпня',
    '09': 'вересня',
    10: 'жовтня',
    11: 'листопада',
    12: 'грудня',
  };
  const order_id = order?.order_id;
  const fullTextDate = `«${order?.createDate?.d}»  ${text_m[order?.createDate?.m]}  ${
    order?.createDate?.y
  } року`;
  const createDate =
    (order?.createDate?.d || '') +
    '.' +
    (order?.createDate?.m || '') +
    '.' +
    (order?.createDate?.y || '');
  const order_num =
    (order?.main_id || order?.order_id || '') +
    '-' +
    createDate.split('.').join('') +
    (bill?.num ? '/' + bill?.num : '');

  const program_start_date = (
    order?.program_start_date ||
    order?.selectedValues?.program_start_date ||
    ''
  )
    .split('-')
    .join('.');
  const program_end_date = (
    order?.program_end_date ||
    order?.selectedValues?.program_end_date ||
    ''
  )
    .split('-')
    .join('.');
  const program_start_year = moment(program_start_date, 'DD.MM.YYYY').year();
  const program_end_year = moment(program_end_date, 'DD.MM.YYYY').year();
  const abroad_education_school = order?.abroad_education?.school || '';
  const abroad_education_date = (order?.abroad_education?.date || '')
    .split('-')
    .join('.');
  const abroad_education_course = order?.abroad_education?.course || '';
  const abroad_education_course_progression =
    order?.abroad_education?.course_progression || '';
  const abroad_education_city = order?.abroad_education?.city || '';
  const abroad_education_details = order?.abroad_education?.details || '';
  const abroad_education = !!(
    abroad_education_school ||
    abroad_education_date ||
    abroad_education_course ||
    abroad_education_course_progression ||
    abroad_education_city ||
    abroad_education_details
  );
  const ukraine_education_attraction_channel =
    order?.ukraine_education?.attraction_channel || '';
  const ukraine_education_english_level = order?.ukraine_education?.english_level || '';
  const ukraine_education_education_grup =
    order?.ukraine_education?.education_group ||
    order?.ukraine_education?.education_grup ||
    '';
  const ukraine_education = !!(
    ukraine_education_english_level || ukraine_education_education_grup
  );
  const currency = order?.currency || order?.selectedValues?.currency || 'UAH';
  const currencyStr =
    currency === 'EUR' ? 'євро' : currency === 'USD' ? 'доларів США' : 'гривень';
  const date = bill?.date || createDate;
  const conversion_rate =
    parseFloat(
      bill?.summ_rate || order?.conversion_rate || order?.selectedValues?.conversion_rate
    )?.toFixed(2) || '';
  const amount_payable =
    bill?.summ || order?.amount_payable || order?.selectedValues?.amount_payable;
  const conversion_description =
    currency === 'UAH'
      ? ''
      : `"Банк - АТ КБ "Приватбанк", валюта ${currency} валютний курс ${conversion_rate} грн. на 9-00 ${date} року
  Загальна сума валюти за Рахунком ${amount_payable} ${currency}`;
  const vat = order?.vat;
  return {
    vat,
    fullTextDate,
    createDate,
    order_num,
    order_id,
    date: bill?.date || createDate,
    amount_payable,
    abroad_education,
    abroad_education_school,
    abroad_education_date,
    abroad_education_course,
    abroad_education_course_progression,
    abroad_education_city,
    abroad_education_details,
    ukraine_education,
    ukraine_education_attraction_channel,
    ukraine_education_english_level,
    ukraine_education_education_grup,
    conversion_rate,
    conversion_description,
    currency,
    currencyStr,
    program_start_date,
    program_end_date,
    program_start_year,
    program_end_year,
    program_name_for_docs:
      order?.program_name_for_docs ||
      order?.selectedValues?.program_name_for_docs ||
      order?.programName?.program_name_for_docs ||
      '',
    full_price: parseInt(
      (
        (order?.full_price ||
          order?.selectedValues?.full_price ||
          order?.programName?.full_price ||
          '') + ''
      ).split('.')[0]
    )
      .toLocaleString()
      .replace(/|\.|-/g, ' '),
    discountStr: parseInt(
      (order?.discount || order?.selectedValues?.discount || 0).toString().split('.')[0]
    )
      .toLocaleString()
      .replace(/|\.|-/g, ' '),
    discount: order?.discount || order?.selectedValues?.discount || 0,
    price_wiht_discountStr: parseInt(
      (order?.price_wiht_discount || order?.selectedValues?.price_wiht_discount || 0)
        .toString()
        .split('.')[0]
    )
      .toLocaleString()
      .replace(/|\.|-/g, ' '),
    price_wiht_discount:
      order?.price_wiht_discount || order?.selectedValues?.price_wiht_discount,
    amount_payable_in_PLN_str: parseInt(
      (
        bill?.summ_in_UAH ||
        bill?.summ ||
        order?.amount_payable_in_PLN ||
        order?.selectedValues?.amount_payable_in_PLN ||
        '0'
      )
        .toString()
        .split('.')[0]
    )
      .toLocaleString()
      .replace(/|\.|-/g, ' '),
    amount_payable_in_PLN:
      bill?.summ_in_UAH ||
      bill?.summ ||
      order?.amount_payable_in_PLN ||
      order?.selectedValues?.amount_payable_in_PLN,

    recipient_name:
      bill?.recipient?.name ||
      order?.recipient?.name ||
      order.recipient?.title ||
      order.selectedValues?.recipient?.name ||
      '',
    recipient_edrpou:
      bill?.recipient?.edrpou ||
      order?.recipient?.edrpou ||
      order?.selectedValues?.recipient?.edrpou ||
      '',
    recipient_bank:
      bill?.recipient?.bank ||
      order?.recipient?.bank ||
      order?.selectedValues?.recipient?.bank ||
      '',
    recipient_iban:
      bill?.recipient?.iban ||
      order?.recipient?.iban ||
      order?.selectedValues?.recipient?.iban ||
      '',
    recipient_euro_account:
      bill?.recipient?.euro_account ||
      order?.recipient?.euro_account ||
      order?.selectedValues?.recipient?.euro_account ||
      '',
    recipient_zloty_account:
      bill?.recipient?.zloty_account ||
      order?.recipient?.zloty_account ||
      order?.selectedValues?.recipient?.zloty_account ||
      '',
    recipient_details:
      bill?.recipient?.details ||
      order?.recipient?.details ||
      order?.selectedValues?.recipient?.details ||
      '',
    recipient_place:
      bill?.recipient?.place ||
      order?.recipient?.place ||
      order?.selectedValues?.recipient?.place ||
      '',
    client_student_name: order?.client?.student_name || '',
    client_payer_name: order?.client?.payer_name || '',
    client_payer_tax_ID: order?.client?.payer_tax_ID || '',
    client_address: order?.client?.address || '',
    client_passport: order?.client?.passport || '',
    client_birth_date: (order?.client?.birth_date).split('-').join('.') || '',
    client_now_class_course: order?.client?.now_class_course || '',
    offer_agreement_link:
      bill?.offer_agreement_link ||
      order?.offer_agreement_link ||
      order?.selectedValues?.offer_agreement_link ||
      order?.recipient?.offer_agreement_link ||
      '',
    fondy_link: order?.fondy_link || '',
    shedule_payments_fields: (order?.shedule_payments_fields || [])
      .map((cnt, num) => {
        const ndate = ((order.shedule_payments_fields[num] || {}).date || '')
          .split('-')
          .join('.');

        const nsumm = (order.shedule_payments_fields[num] || {}).summa || '';
        return (order.shedule_payments_fields || {})[num]
          ? nsumm !== ''
            ? `дата: ${ndate}, сума: ${nsumm}`
            : ''
          : '';
      })
      .join(';  '),
    payment: order?.payment || '',
    manager_full_name: order?.manager?.firstName + ' ' + order?.manager?.lastName || '',
  };
};
