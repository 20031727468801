import { getManagersFromServer } from './requests';

export const model = {
  state: {
    managers: [],
  },
  reducers: {
    resetState: () => ({ managers: [] }),
    setManagers(state, managers) {
      return { ...state, managers };
    },
    addNewManager(state, name) {
      return {
        ...state,
        managers: [{ id: Date.now(), name }, ...state.managers],
      };
    },
  },
  effects: (dispatch) => ({
    async getManagersFromServer({ page = 1, limit = 10, sortInfo = {}, query }) {
      try {
        const result = await getManagersFromServer({
          sortInfo,
          page,
          limit,
          query,
        });
        dispatch.catalogs.setManagers(result);

        return result;
      } catch (error) {
        // Notification.error({
        //   placement: 'bottomRight',
        //   message: error.message,
        //   description: error.message,
        // });
      }
    },
  }),
};
