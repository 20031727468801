import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Utils
import { useHandleFields } from 'utils';

// UI
import { Flex, Button } from 'ui';

// Components
import Recipient from '../../../components/Recipient';
import { Select, Input, SubmitButton, Label } from 'components/Fields';

// Styled
const placeholder = 'Не вибрано';

// Redux
const getDataFromStore = ({
  main: { managers, selectedValues, ukraine_education, programInfo },
  users: { userData },
}) => ({
  managers,
  selectedValues,
  ukraine_education,
  programInfo,
  userData,
});
const getMethodsFromStore = ({
  main: { setState, getManagers },
  users: { getProfileInfo },
}) => ({
  setState,
  getProfileInfo,
  getManagers,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    selectedValues = {},
    ukraine_education = {},
    managers = [],
    programInfo = {},
    setState = () => {},
    getProfileInfo = () => {},
    getManagers = () => {},
  }) => {
    const { manager = '', programType, programName, paymant } = selectedValues;
    const { attraction_channel } = ukraine_education;
    const { offer_agreement_link = '' } = programInfo;
    let managerName = '';

    const _saleManagersOptions = managers.map((saleManager = {}) => {
      const { name = '', id } = saleManager;
      if (id === manager) managerName = name;
      return { label: name, value: id, key: id };
    });

    const saleManagerDisabled = !_saleManagersOptions.length;

    const recipient = selectedValues.recipient || {};
    const handleFields = useHandleFields(setState);
    useEffect(() => {
      getProfileInfo();
      getManagers();
    }, [getProfileInfo]);
    return (
      <Flex direction="column" align="flex-start" margin="30px 0 0 0" textAlign="left">
        <Recipient _recipient={recipient} _offer_agreement_link={offer_agreement_link} />

        <Flex align="flex-end">
          <Label required text="" margin="0 10px 0 10px" style={{ width: 'max-content' }}>
            <Select
              style={{ minWidth: '150px' }}
              showSearch
              addonBefore="Менеджер продажів"
              options={_saleManagersOptions}
              placeholder={placeholder}
              value={manager || null}
              disabled={saleManagerDisabled}
              onChange={handleFields('selectedValues.manager')}
              filterOption={(input, option) =>
                option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            />
          </Label>

          <Label margin="0 10px 0 10px">
            <Input
              placeholder="Додати нового менеджера"
              value={managerName || manager}
              // disabled={role === 'manager'}
              disabled={true}
              onChange={handleFields('selectedValues.manager')}
            />
          </Label>
        </Flex>
        <Flex margin="30px 0 0 0">
          <Button type="primary" onClick={() => setState({ step: 1 })}>
            Назад
          </Button>
          <SubmitButton
            text="Далі >"
            disabled={
              !programType || !programName || !paymant || !manager || !attraction_channel
            }
          />
        </Flex>
      </Flex>
    );
  }
);
