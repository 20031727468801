import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import TimeNewRoman from './fonts/Times_New_Roman.ttf';
// import TimeNewRoman_bold from './fonts/Times_New_Roman_Bold.ttf';
import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
// import LiberationSerifItalick from './fonts/LiberationSerif-Italic.ttf';
// import LiberationSerifBoldItalic from './fonts/LiberationSerif-BoldItalic.ttf';

import { TransformNumberInWords } from 'services/NumbersInWords';
import { trasformData } from 'utils/restructuringOrderData';

// Font.register({
//   family: 'TimeNewRoman',
//   src: TimeNewRoman,
// });
// Font.register({ family: 'TimeNewRoman_bold', src: TimeNewRoman_bold });
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 11,
    fontFamily: 'LiberationSerif',
    // fontFamily: 'TimeNewRoman',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
    fontWeight: 'bold',
    // fontFamily: 'LiberationSerifBold',
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
});
export const Account = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ width: '70%', border: 1, marginBottom: 20 }}></Text>
          <Text style={styles.m_b}>
            {`Замовлення № ${orderValues.order_num} від ${orderValues.date} року`}
          </Text>
          <Text style={{ fontWeight: 'bold', marginBottom: 50 }}>
            {`Виконавець: ${orderValues.recipient_name}`}
          </Text>
          <Text style={styles.m_b}>{`Замовник: ${orderValues.client_payer_name}`}</Text>
          <Text style={styles.m_b}>
            {`IПН Замовника: ${orderValues.client_payer_tax_ID}`}
          </Text>
          <Text style={styles.m_b}>{`Паспорт: ${orderValues.client_passport}`}</Text>
          <Text style={styles.m_b}>
            {`Фактична адреса проживання: ${orderValues.client_address}`}
          </Text>
          <Text style={styles.m_b}>
            {`Дата народження: ${orderValues.client_birth_date}`};
          </Text>
          <Text style={styles.m_b}>
            {`Клас/курс: ${orderValues.client_now_class_course}`}
          </Text>
          <Text style={styles.m_b}>
            Графік оплат: &nbsp;
            {orderValues.shedule_payments_fields}
          </Text>
          <Text style={styles.m_b}>{`Студент: ${orderValues.client_student_name}`}</Text>
          <Text style={styles.m_b}>
            {`Дата початку програми: ${orderValues?.program_start_date}`}
          </Text>
          <Text style={styles.m_b}>
            {`Дата закінчення програми: ${orderValues?.program_end_date}`}
          </Text>
          {orderValues.abroad_education ? (
            <View>
              <Text style={{ marginTop: 20, textAlign: 'center', marginBottom: 10 }}>
                ДОДАТКОВА ІНФОРМАЦІЯ ПРО ОСВІТУ ЗА КОРДОНОМ
              </Text>
              {orderValues.abroad_education_school ? (
                <Text style={styles.m_b}>
                  Навчальний заклад: {orderValues.abroad_education_school}
                </Text>
              ) : null}
              {orderValues.abroad_education_date ? (
                <Text style={styles.m_b}>
                  {`Дата початку навчання за кордоном: ${orderValues.abroad_education_date} року`}
                </Text>
              ) : null}
              {orderValues.abroad_education_course ? (
                <Text style={styles.m_b}>
                  Назва освітнього курсу: {orderValues.abroad_education_course}
                </Text>
              ) : null}
              {orderValues.abroad_education_course_progression ? (
                <Text style={styles.m_b}>
                  Прогресія після освітнього курсу:{' '}
                  {orderValues.abroad_education_course_progression}
                </Text>
              ) : null}
              {orderValues.abroad_education_details ? (
                <Text style={styles.m_b}>
                  Деталі додаткових послуг: {orderValues.abroad_education_details}
                </Text>
              ) : null}
            </View>
          ) : null}
          {orderValues.ukraine_education ? (
            <View>
              <Text style={{ marginTop: 20, textAlign: 'center', marginBottom: 10 }}>
                ДОДАТКОВА ІНФОРМАЦІЯ ПРО НАВЧАННЯ В УКРАЇНІ
              </Text>
              {orderValues.ukraine_education_education_grup ? (
                <Text style={styles.m_b}>
                  Номер групи навчання: {orderValues.ukraine_education_education_grup}
                </Text>
              ) : null}
              {orderValues.ukraine_education_english_level ? (
                <Text style={styles.m_b}>
                  Рівень англійської на сьогодні:{' '}
                  {orderValues.ukraine_education_english_level}
                </Text>
              ) : null}
            </View>
          ) : null}
          <View
            style={{
              flexDirection: 'row',
              marginTop: 20,
              textAlign: 'center',
              fontSize: 9,
            }}>
            <Text style={{ border: 1, width: 30, padding: 3 }}>№</Text>
            <Text style={{ border: 1, width: 355, padding: 3, borderLeft: 0 }}>
              Номенклатура
            </Text>
            <Text style={{ border: 1, width: 40, padding: 3, borderLeft: 0 }}>
              Кіл-сть
            </Text>
            <Text style={{ border: 1, width: 50, padding: 3, borderLeft: 0 }}>
              Од. вим.
            </Text>
            {/* <Text style={{ border: 1, width: 55, padding: 3, borderLeft: 0 }}>
              Вартість
            </Text>
            <Text style={{ border: 1, width: 80, padding: 3, borderLeft: 0 }}>
              Cума знижки
            </Text> */}
            <Text style={{ border: 1, width: 70, padding: 3, borderLeft: 0 }}>
              Сума до сплати
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              textAlign: 'center',
              marginBottom: 20,
              fontSize: 9,
            }}>
            <Text style={{ border: 1, width: 30, padding: 3, borderTop: 0 }}>1</Text>
            <Text
              style={{
                border: 1,
                width: 355,
                padding: 3,
                borderLeft: 0,
                borderTop: 0,
                textAlign: 'left',
              }}>
              {orderValues.program_name_for_docs}
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: 3,
                borderLeft: 0,
                borderTop: 0,
              }}>
              1
            </Text>
            <Text
              style={{
                border: 1,
                width: 50,
                padding: 3,
                borderLeft: 0,
                borderTop: 0,
              }}>
              послуга
            </Text>
            {/* <Text
              style={{
                border: 1,
                width: 55,
                padding: 3,
                borderLeft: 0,
                borderTop: 0,
              }}>
              {orderValues.full_price}
              .00
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                padding: 3,
                borderLeft: 0,
                borderTop: 0,
              }}>
              {orderValues.discountStr}
              .00
            </Text> */}
            <Text
              style={{
                border: 1,
                width: 70,
                padding: 3,
                borderLeft: 0,
                borderTop: 0,
              }}>
              {orderValues.price_wiht_discountStr}
              .00
            </Text>
          </View>
          <Text
            style={{
              width: '100%',
              textAlign: 'right',
              fontWeight: 'bold',
              // fontFamily: 'TimeNewRoman_bold',
            }}>
            Разом:{'       '}
            {orderValues.price_wiht_discountStr}
            .00
          </Text>
          <Text
            style={{
              width: 350,
            }}>
            Всього найменувань 1, на суму {orderValues.price_wiht_discountStr}
            .00 {orderValues.currencyStr}.{'\n'}
            {new TransformNumberInWords(
              orderValues.price_wiht_discount,
              orderValues.currency
            ).transform()}
            {'.'}
          </Text>
          <Text
            style={{
              fontWeight: 'bold',
              // fontFamily: 'TimeNewRoman_bold',
              fontSize: '9.5',
              marginBottom: 20,
              marginTop: 5,
            }}>
            Вартість послуг Виконавця сплачується Замовником у гривневому еквіваленті
            відповідно до комерційного курсу банківської установи України, яка зазначена у
            виставленому Виконавцем рахунку на оплату.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
export default Account;
