import React, { useState } from 'react';
import { connect } from 'react-redux';

// Components
import { Input, SubmitButton } from 'components/Fields';
// styles
import { Container, inputHeight } from './styles';
const TextField = inputHeight(Input, '40px');

const getDataFromStore = () => ({});
const getMethodsFromStore = ({ adminProgramDates: { getAllProgramDates } }) => ({
  getAllProgramDates,
});

export const Search = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ getAllProgramDates = () => {} }) => {
  const [code, setCode] = useState('');
  const search = async () => {
    const params = { code };
    getAllProgramDates(params);
  };
  return (
    <Container>
      <TextField
        addonBefore="Код номенклатури"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <SubmitButton text="пошук" onClick={search} />
    </Container>
  );
});

export default Search;
