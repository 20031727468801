import React, { useState } from 'react';
import { components as _components } from './EditUserModalComponents';
import { connect } from 'react-redux';

// Components
import { Input, Text, Select, InputPassword, Checkbox } from 'ui';

// Styled
import { ModalContent } from '../UsersTable/styles';

const ComponentsList = {
  input: Input,
  select: Select,
  inputPassword: InputPassword,
  checkBox: Checkbox,
};

// Redux
const getDataFromStore = ({
  adminUsers: { modalData, currentRecord },
  users: { roles },
}) => ({
  roles,
  modalData,
  currentRecord,
});
const getMethodsFromStore = ({ adminUsers: { setModalData } }) => ({
  setModalData,
});

/**
 * Component
 */
export const EditUserModal = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ modalData = {}, roles = [], setModalData = () => {}, currentRecord = {} }) => {
  const [localData, setLocalData] = useState(modalData);
  console.log('currentRecord', currentRecord);
  const handleFields = (key) => (e) => {
    if (e?.target?.type === 'checkbox') {
      console.log('if');
      console.log(e);
      setLocalData({ ...localData, [key]: e.target.checked || false });
    } else {
      setLocalData({ ...localData, [key]: e.target ? e.target.value : e });
    }
  };
  const components = _components({ handleFields });
  const selectData = {
    roles,
  };

  return (
    <ModalContent
      onMouseLeave={() => {
        setModalData({ ...modalData, ...localData });
        setLocalData({ ...localData });
      }}>
      {components.map((comp) => {
        const Field = ComponentsList[comp.type];
        const additionalProps = {};

        if (comp.isHasItems) {
          additionalProps.items = (selectData[comp.listName] || []).map((fld, k) => (
            <option key={k} value={fld}>
              {fld}
            </option>
          ));
        }

        const { placeholder = '' } = comp;

        return (
          <div>
            <Text>{placeholder ? `${placeholder}:` : ''}</Text>
            <Field
              className="Field"
              {...comp.behaviours}
              placeholder={comp.placeholder}
              value={localData[comp.value]}
              checked={
                localData[comp.checked] === undefined
                  ? currentRecord[comp.checked]
                  : localData?.[comp.checked]
              }
              defaultValue={
                modalData[comp.defaultValue] || currentRecord[comp.defaultValue]
              }
              defaultChecked={
                modalData[comp.defaultChecked] || currentRecord[comp.defaultChecked]
              }
              {...additionalProps}
            />
          </div>
        );
      })}
    </ModalContent>
  );
});
