const chekData = [
  'Британський табір Olympic - літній 2021 заїзд',
  'Британський табір Admiral - літній 2021 заїзд',
  'Британський табір Karpaty - літній 2021 заїзд',
];
const chekToFielData = ['Групові поїздки Groups', 'Групові поїздки Groups - EUR'];
export const CheckProgramName = (programName = '') => {
  let res = false;
  chekData.map((item) => {
    if (item === programName) {
      res = true;
    }
  });
  return res;
};

export const CheckProgramNameToFields = (programName = '') => {
  let res = false;
  chekToFielData.map((item) => {
    if (item === programName) {
      res = true;
    }
  });
  return res;
};
export default CheckProgramName;
