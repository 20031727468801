import React from 'react';
import { connect } from 'react-redux';

import InitialState from '../../controllers/initialState';

// PDF GENERATOR
import { CreatePDF } from '../PdfCreator/CreatePDF';

// UI
import { Flex, Button as _button } from 'ui';
// Utils
import { ShowAgreement } from 'utils/checkShowAgreement';
// Styled
import { marginLeft, dateContainer } from './styles';

const Button = marginLeft(_button);
const DateContainer = dateContainer(Flex);

const getDataFromStore = ({ main, users: { userData } }) => ({ main, userData });
const getMethodsFromStore = ({ main: { setState, sendOrderPDFToEmail } }) => ({
  setState,
  sendOrderPDFToEmail,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(({ main, userData = {}, setState = () => {}, sendOrderPDFToEmail = () => {} }) => {
  const { order, mailPDFSendError, mailPDFSendSuccess } = main;
  const { programType, programName, payment, paymant } = order;
  const { role } = userData;
  const agreementShow = ShowAgreement({
    programType,
    programName,
    paymant: paymant || payment,
  });
  const handleResendPDFtoEmail = () => {
    sendOrderPDFToEmail(order);
  };
  const handleInitState = () => {
    setState(InitialState);
  };

  let newOrderDate = '';
  let newOrderTime = '';

  try {
    newOrderDate = `${order?.createDate?.d || ''}-${order?.createDate?.m || ''}-${
      order?.createDate?.y || ''
    }`;
    newOrderTime =
      (new Date(order?.createDate?.date || '').toISOString()?.split('T')[1] || '')?.split(
        '.'
      )[0] || '';
  } catch (error) {
    console.log(error.message);
  }

  return (
    <Flex direction="column" align="flex-start">
      <DateContainer>
        Дата: {newOrderDate}, час: {newOrderTime}, № {order?.order_id}
        <br />
        {mailPDFSendSuccess && 'Додаткова угода відправлена на почту!'}
      </DateContainer>
      <Flex className={'buttons_container'} justify="space-around" wrap="wrap">
        {programType === 'Оплати в школу аттестати/сертифікати' ? (
          <a
            href={'https://international.study.ua/pdf/agreementpayment.pdf'}
            target={'_blank'}
            rel="noopener noreferrer">
            ДОГОВІР
          </a>
        ) : (
          <>
            <CreatePDF
              currency="EUR"
              type={programType === 'Лицей Кипр' ? 'newBill' : 'bill'}
              order={order}
            />
            <CreatePDF
              type={programType === 'Лицей Кипр' ? 'newBill' : 'bill'}
              order={order}
              isCurrencyPLN
              currency="PLN"
            />
          </>
        )}
      </Flex>
      <Flex className={'buttons_container'} justify="space-around" wrap="wrap">
        {order?.order_id && mailPDFSendError ? (
          <Button type="primary" onClick={handleResendPDFtoEmail}>
            Повторно відправити Email
          </Button>
        ) : (
          ''
        )}
        <Button type="primary" onClick={handleInitState}>
          Новий договір
        </Button>
      </Flex>
    </Flex>
  );
});
