import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Flex, Loader } from 'ui';
import { Form, container, Field, LeftText, ErrorText } from './styles';
import { connect } from 'react-redux';

import { getFingerPrint } from 'plugins/fingerprint';

// Components
import { Label, SubmitButton } from 'components/Fields';

const Container = container(Flex);

const getDataFromStore = ({ users: { loading, userData } }) => ({ loading, userData });
const getMethodsFromStore = ({ users: { setState, loginIn } }) => ({
  setState,
  loginIn,
});
export default connect(
  getDataFromStore,
  getMethodsFromStore
)(({ loading = false, setState = () => {}, loginIn = () => {} }) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);
    setState({ loading: true });
    // setTimeout(() => setState({ loading: false }), 3000);
    await loginIn(data);
  };

  useEffect(() => {
    setTimeout(() => {
      console.log(getFingerPrint());
    }, 1000);
  }, []);

  return (
    <Container direction="column">
      <div>
        <h1>Авторизація</h1>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {loading ? <Loader /> : ''}
        <Label required>
          <Field className="ant-input-group-wrapper ant-input-group">
            <LeftText className="ant-input-group-addon">Login: </LeftText>
            <input
              className="ant-input"
              name="email"
              type="email"
              ref={register({ required: true })}
            />{' '}
          </Field>
          <ErrorText>{errors.email && 'Login is required.'}</ErrorText>
        </Label>
        <Label required>
          <Field className="ant-input-group-wrapper ant-input-group">
            <LeftText className="ant-input-group-addon">Password:</LeftText>
            <input
              className="ant-input"
              name="password"
              type="password"
              ref={register({ required: true })}
            />{' '}
          </Field>
          <ErrorText>{errors.password && 'Password is required.'}</ErrorText>
        </Label>
        <Label>
          <SubmitButton text="Login" />
        </Label>
      </Form>
    </Container>
  );
});
