import styled from 'styled-components';

export const container = (Flex) => styled(Flex)`
  padding: 0 5% 0 5%;
`;

export const Form = styled.form`
  max-width: 500px;
  width: 100%;
  padding: 2%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 50px;
  text-align: left;
`;
export const Field = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;
export const LeftText = styled.div`
  width: auto;
  background-color: #fafafa;
  padding: 4px 11px;
`;
export const ErrorText = styled.div`
  width: 100%;
  padding: 0 10px;
  color: red;
`;
