import { RequestError } from 'utils';
import { login, users, auth } from '../services/request';

export const requests = {
  async getProfileInfo(params = {}) {
    const response = await users.post('getInfo', true, params);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async getRoles(params = {}) {
    const response = await users.get('getroles', true, params);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async editProfileInfo(payload) {
    const response = await users.put('editbyuser', true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async loginIn(data) {
    const response = await login.post('', false, data);

    if (!response || response.message || response.error) throw new RequestError(response);

    return response;
  },

  async refreshToken(params = { accessToken: '', fingerPrint: '' }) {
    const response = await auth.post('refreshToken', true, params);

    if (!response || response.message || response.error) throw new RequestError(response);

    return response;
  },
};

export default requests;
