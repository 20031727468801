import { RequestError } from 'utils';

// Config
import config from 'config';

import {
  pb,
  orders,
  locations,
  recipients,
  programTypes,
  programDetails,
  paymentMethods,
  users,
  currencyChange,
  currencyChangeId,
} from '../services/request';

const {
  PAYMENT_TYPE_MONOBANK,
  MONOBANK_EDRPOU,
  MONOBANK_IBAN,
  MONOBANK_BANK,
  IT_DIRECTION,
  MONOBANK_EDRPOU_IT,
  MONOBANK_BANK_IT,
  MONOBANK_IBAN_IT,
} = config;

export const requests = {
  async getCurrencies() {
    const result = await pb.get();

    if (!Array.isArray(result))
      throw new Error('Result must be an array! Check API /pb route!');

    // const isPLNExists = (result || []).find((currency) => currency.ccy === 'PLN');
    // const isEURExists = (result || []).find((currency) => currency.ccy === 'EUR');

    // if (!isPLNExists || !isEURExists)
    //   throw new Error('Result must contains usd and eur!');

    return result;
  },

  async postCurrencies() {
    return await pb.post();
  },

  async getProgramTypes() {
    const result = await programTypes.get();

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found program types!');
    }

    return result || [];
  },

  async getEditAccessValue(body) {
    return await currencyChangeId.get('', true, body);
  },

  async setCurrencies(body) {
    return await currencyChange.patch('', true, body);
  },

  async getProgramNames(program_type) {
    const result = await programDetails.post('nomenclature1c/program', false, {
      program_type,
    });

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found program names');
    }

    return result || [];
  },

  async getPaymentMethods({ program_type, nomenclature_1s }) {
    const result = await paymentMethods.post('program', false, {
      program_type,
      nomenclature_1s,
    });

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found payment methods');
    }

    return result || [];
  },

  async getPaymentLocations({ program_type, nomenclature_1s, payment_type }) {
    const result = await locations.post('program', false, {
      program_type,
      nomenclature_1s,
      payment_type,
    });

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found locations');
    }

    return result || [];
  },

  async getRecipient({ program_type, nomenclature_1s, payment_type, location }) {
    const programInfo = await programDetails.post('get_info', false, {
      program_type,
      nomenclature_1s,
      payment_type,
      location,
    });

    if (!programInfo || !programInfo.edrpou) {
      throw new Error(programInfo.error || 'Not found edrpou');
    }

    const recipient = await recipients.post('by_edrpou', false, {
      edrpou:
        payment_type === PAYMENT_TYPE_MONOBANK
          ? program_type === IT_DIRECTION
            ? MONOBANK_EDRPOU_IT
            : MONOBANK_EDRPOU
          : programInfo.edrpou,
    });
    if (payment_type === PAYMENT_TYPE_MONOBANK) {
      if (program_type === IT_DIRECTION) {
        recipient.iban = MONOBANK_IBAN_IT;
        recipient.bank = MONOBANK_BANK_IT;
      } else {
        recipient.iban = MONOBANK_IBAN;
        recipient.bank = MONOBANK_BANK;
      }
    }

    if (!recipient || !recipient._id) {
      throw new Error(recipient.error || 'Not found recipient');
    }

    return { recipient, programInfo };
  },
  // study/location/
  async getEducationLocations({ code }) {
    const result = await programDetails.get('study/location/get', false, { code });

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found locations');
    }

    return result || [];
  },

  async getManagers() {
    const result = await users.get('getmanagers', true);

    if (result.message || result.error) throw new RequestError(result);

    return result || [];
  },

  async createOrder(payload) {
    const result = await orders.post('', true, payload);

    if (!result || result.message || result.error) {
      throw new Error(result.message || 'Order not created');
    }

    return result;
  },

  async sendOrderPDFToEmail(payload) {
    const result =
      (await orders.post('sendemail', true, payload, {
        type: 'files',
        // name of form fields for backend
        fileNames: ['file', 'wishes', 'subject'],
      })) || {};

    if (result.message || result.error) {
      throw new RequestError(result);
    }

    return result;
  },
  // async order_update_1c(payload) {
  //   const result = await orders_1c.post('update/order', true, payload);
  //
  //   if (!result || result.message || result.error) {
  //     throw new Error(result.message || 'order not synhronazed with 1C');
  //   }
  //
  //   return result;
  // },
  // async bill_update_1c(payload) {
  //   const result = await orders_1c.post('update/bill', true, payload);
  //
  //   if (!result || result.message || result.error) {
  //     throw new Error(result.message || 'bill not synhronazed with 1C');
  //   }
  //
  //   return result;
  // },
  // async all_update_1c(payload = {}) {
  //   const result = await orders_1c.post('update/all', true, payload);
  //
  //   if (!result || result.message || result.error) {
  //     throw new Error(result.message || 'not synhronazed with 1C');
  //   }
  //
  //   return result;
  // },
  async getCountryList() {
    const result = await programDetails.get('country/list', true);

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found countrys!');
    }

    return result || [];
  },
  async getSchoolList() {
    const result = await programDetails.get('school/list', true);

    if (!result || result.length === 0) {
      throw new Error(result.message || 'Not found schools!');
    }

    return result || [];
  },
  async searchCleintPayer(payload) {
    const result = await orders.post('cleint_1c', true, payload);
    if (!result || result.message || result.error) {
      throw new Error(result.message || 'No search result');
    }

    return result;
  },
  async searchCleintStudent(payload) {
    const result = await orders.post('student_1c', true, payload);
    if (!result || result.message || result.error) {
      throw new Error(result.message || 'No search result');
    }
    return result;
  },
};

export default requests;
