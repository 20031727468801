import styled from 'styled-components';

export const container = (Flex) => styled(Flex)`
  padding: 0 5% 0 5%;
`;

export const PriceForm = styled.form`
  max-width: 800px;
  width: 100%;
  padding: 2%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 50px;
  text-align: left;
`;

export const scheduleContainer = (Flex) => styled(Flex)`
  margin: 10px 0;
`;

export const scheduleField = (Field) => styled(Field)`
  width: 40%;
  margin-left: 10px;
`;
