import React from 'react';
import styled from 'styled-components';

export const s = (spin) => {
  const Spin = styled(spin)``;

  return (props) => <Spin {...props} />;
};

export default s;
