import axios from 'axios';
import config from '../config';

const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];

let URL = window?.location?.origin || config.PROD_URL;

if (LOCAL_DOMAINS.includes((window || { location: {} }).location.hostname)) {
  URL = `http://localhost:${config.API_PORT}`;
}

export async function initLoadRequest() {
  // get reques
  let res = [];
  await axios
    .get(`${URL}/api/program-types/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      res = response.data;
    })
    .catch(function () {
      // console.log(error);
    })
    .finally(function () {});

  return res;
}
export async function getProgramNames(e) {
  // get request
  let res = [];
  await axios
    .get(`${URL}/api/programs/program-type/` + e, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      res = response.data;
    })
    .catch(function () {
      // console.log(error);
    })
    .finally(function () {});

  return res;
}
export async function getPaymant(e) {
  // get request
  let res = [];
  await axios
    .get(`${URL}/api/payment-methods/program/` + e, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      res = response.data;
    })
    .catch(function () {
      // console.log(error);
    })
    .finally(function () {});
  return res;
}
export async function getLocation(e) {
  // get
  // get request
  let res = [];
  await axios
    .get(`${URL}/api/locations/payment-method/` + e, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      res = response.data;
    })
    .catch(function () {
      // console.log(error);
    })
    .finally(function () {});
  return res;
}
export async function getRecipient(e) {
  // get request
  let res = [];
  await axios
    .get(`${URL}/api/recipients/location/` + e, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      res = response.data;
    })
    .catch(function () {
      // console.log(error);
    })
    .finally(function () {});
  return res;
}

export async function sentDate(e) {
  // get
  // get request

  let res = {};
  await axios
    .post(`${URL}/api/orders/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: { ...e },
    })
    .then(function (response) {
      // console.log(response.data);
      res = response.data;
    })
    .catch(function () {
      // console.log(error);
    })
    .finally(function () {});
  return res;
}
