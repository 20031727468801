export const EUR = {
  nom: {
    1: 'євро',
    2: 'євро',
    3: 'євро',
    4: 'євро',
    others: 'євро',
  },
  lit: {
    1: 'євроцент',
    2: 'євроценти',
    3: 'євроценти',
    4: 'євроценти',
    others: 'євроцентів',
  },
  lessThen10: {
    man: {
      1: 'один',
      2: 'два',
      3: 'три',
      4: 'чотири',
      5: "п'ять",
      6: 'шість',
      7: 'сім',
      8: 'вісім',
      9: 'девять',
    },
    woman: {
      1: 'одна',
      2: 'дві',
      3: 'три',
      4: 'чотири',
      5: "п'ять",
      6: 'шість',
      7: 'сім',
      8: 'вісім',
      9: 'девять',
    },
  },
  from10to19: {
    10: 'десять',
    11: 'одинадцять',
    12: 'дванадцять',
    13: 'тринадцять',
    14: 'чотирнадцять',
    15: "п'ятнадцять",
    16: 'шістнадцять',
    17: 'сімнадцять',
    18: 'вісімнадцять',
    19: "дев'ятнадцять",
  },
  from20to90: {
    20: 'двадцять',
    30: 'тридцять',
    40: 'сорок',
    50: "п'ятдесят",
    60: 'шістдесят',
    70: 'сімдесят',
    80: 'вісімдесят',
    90: "дев'яносто",
  },
  from100to1000: {
    100: 'сто',
    200: 'двісті',
    300: 'триста',
    400: 'чотириста',
    500: "п'ятсот",
    600: 'шістсот',
    700: 'сімсот',
    800: 'вісімсот',
    900: "дев'ятсот",
  },
  from1000to1000000: {
    1000: 'тисяча',
    2000: 'тисячі',
    3000: 'тисячі',
    4000: 'тисячі',
    others: 'тисяч',
  },
  1000000: {
    1: 'мільйон',
    2: 'мільйона',
    3: 'мільйона',
    4: 'мільйона',
    others: 'мільйонів',
  },
};
