import React from 'react';

// Antd
import ASelect from 'antd/lib/select';
import AForm from 'antd/lib/form';
import AInput from 'antd/lib/input';
import ADatePicker from 'antd/lib/date-picker';
import AButton from 'antd/lib/button';
import AInputNumber from 'antd/lib/input-number';
import ACarousel from 'antd/lib/carousel';
import ACheckbox from 'antd/lib/checkbox';
import ATooltip from 'antd/lib/tooltip';
import ANotification from 'antd/lib/notification';
import ASpin from 'antd/lib/spin';
import AMenu from 'antd/lib/menu';
import ATable from 'antd/lib/table';
import AModal from 'antd/lib/modal';

// OWN UI Components
import UIButton from './Button';
import { UIButtonError } from './Button';
import UISelect from './Select';
import UIFlex from './Flex';
import UIForm from './Form';
import UIInput from './Input';
import UIDatePicker from './DatePicker';
import UITextArea from './TextArea';
import UITitle from './Title';
import UIText from './Text';
import UIInputNumber from './InputNumber';
import UICarousel from './Carousel';
import UIInputGroup from './InputGroup';
import UICheckbox from './Checkbox';
import UITooltip from './Tooltip';
import UILabel from './Label';
import UILoader from './Loader';
import UISpin from './Spin';
import UIMenu from './Menu';
import UITable from './Table';
import UIModal from './Modal';
import UniversalModal from './UniversalModal';
import UIInputPassword from './InputPassword';

// Extract additional components
const { SubMenu, Item, ItemGroup } = AMenu;
const { RangePicker } = ADatePicker;
// Export wrapped components
export const Loader = UILoader;
export const Button = UIButton(AButton);
export const ButtonError = UIButtonError(AButton);
export const Select = UISelect(ASelect);
export const Text = UIText;
export const Flex = UIFlex;
export const Form = UIForm(AForm);
export const Label = UILabel;
export const Input = UIInput(AInput);
export const DatePicker = UIDatePicker(ADatePicker);
export const RangeDatePicker = UIDatePicker(RangePicker);
export const TextArea = UITextArea(AInput.TextArea);
export const InputNumber = UIInputNumber(AInputNumber);
export const Carousel = UICarousel(ACarousel);
export const InputGroup = UIInputGroup(AInput.Group);
export const Checkbox = UICheckbox(ACheckbox);
export const Tooltip = UITooltip(ATooltip);
export const Notification = ANotification;
export const Spin = UISpin(ASpin);
export const Table = UITable(ATable);
export const Modal = UIModal(AModal);
export const InputPassword = UIInputPassword(AInput.Password);
export const Menu = UIMenu((p) =>
  p.type === 'submenu' ? (
    <SubMenu {...p} />
  ) : p.type === 'group' ? (
    <ItemGroup {...p} />
  ) : p.type === 'item' ? (
    <Item {...p} />
  ) : (
    <AMenu {...p} />
  )
);
export const Title = UITitle((p) =>
  p.type === 'xxl' ? (
    <h1 aria-hidden {...p} />
  ) : p.type === 'xl' ? (
    <h2 aria-hidden {...p} />
  ) : p.type === 'l' ? (
    <h3 aria-hidden {...p} />
  ) : p.type === 'm' ? (
    <h4 aria-hidden {...p} />
  ) : p.type === 's' ? (
    <h5 aria-hidden {...p} />
  ) : (
    <h6 aria-hidden {...p} />
  )
);

export { UniversalModal };

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;
