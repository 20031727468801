import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Containers
import Actions from './Actions';
import AddUserButton from './AddUserButton';

// Components
import { Table } from 'ui';
import _columns from './columns';

const columns = _columns({ Actions, AddUserButton });

const getDataFromStore = ({ adminUsers: { users } }) => ({ users });
const getMethodsFromStore = ({ adminUsers: { getAllUsers } }) => ({ getAllUsers });

export const UsersTable = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  (
    // props
    { users = [], getAllUsers = () => {} }
  ) => {
    useEffect(() => {
      getAllUsers({});
    }, [getAllUsers]);
    return (
      <Table
        bordered
        showHeader={true}
        dataSource={users.map((u, k) => ({ ...u, key: k }))}
        columns={columns}
      />
    );
  }
);

export default UsersTable;
