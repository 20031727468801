import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

// Styled
import { SideMenu, MenuItem } from './styles';

// Utils
import { isAdminPath, isErrorPath } from './controller';
import itemsList from './menu-items';

const getDataFromStore = ({ users: { userData }, _app: { sideMenuWidth } }) => ({
  userData,
  sideMenuWidth,
});
const getMethodsFromStore = ({ _app: { setState } }) => ({ setState });

export const SideBar = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ userData = {}, sideMenuWidth = 0, setState = () => {} }) => {
  const history = useHistory();
  const location = useLocation();
  const { role = '' } = userData;
  // Check if we located in admin or error pages
  const inAdminPage = isAdminPath(location);
  const inErrorPage = isErrorPath(location);
  const isAllowedPage = inAdminPage && !inErrorPage;

  if (!isAllowedPage) setState({ sideMenuWidth: 0 });

  const goToPage = (link) => () => {
    history.push(link);
  };

  const hideMenuOnClickBurger = {
    width: isAllowedPage ? sideMenuWidth : 0,
    display: isAllowedPage ? 'flex' : 'none',
    borderWidth: sideMenuWidth === 0 ? '0px' : '1px',
  };

  const selectedKeys = [];

  return (
    <SideMenu
      mode="inline"
      selectedKeys={selectedKeys}
      style={{ ...hideMenuOnClickBurger }}>
      {itemsList.map((menuItem = {}, index) => {
        const { label = '', Icon = () => '', link = '', access = [] } = menuItem;

        if (link === location.pathname) selectedKeys.push(String(index));

        return (
          <MenuItem
            key={index}
            hidden={access.length ? !access.includes(role) : false}
            onClick={goToPage(link)}
            type="item"
            icon={<Icon />}>
            {label}
          </MenuItem>
        );
      })}
    </SideMenu>
  );
});

export default SideBar;
