import React from 'react';
import { connect } from 'react-redux';

// Components
import { Button } from 'ui';

// Styled
import { ActionButtonsSpan } from './styles';

// Icons
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

// Redux
const getDataFromStore = ({ adminProgramDates: { users } }) => ({
  users,
});

const getMethodsFromStore = ({
  adminProgramDates: { getAllUsers, setModal, setCurrentRecord },
}) => ({
  getAllUsers,
  setModal,
  setCurrentRecord,
});

export const Actions = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ record = {}, setModal = () => {}, setCurrentRecord = () => {} }) => {
  const handleOpenModal = (type) => () => {
    setModal(type);

    setCurrentRecord(record);
  };
  console.log('Actions record ', record);

  return (
    <ActionButtonsSpan>
      <Button className="ActionButtonEdit" onClick={handleOpenModal('edit')}>
        <EditOutlined />
      </Button>
      <Button className="ActionButtonDelete" onClick={handleOpenModal('delete')}>
        <DeleteOutlined />
      </Button>
    </ActionButtonsSpan>
  );
});

export default Actions;
