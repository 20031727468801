import React from 'react';
import Workbook from 'react-xlsx-workbook';

//Components
import { Flex } from '~/ui';
import { notification } from 'antd';

/**
 * Component
 */
export const WorkbookExcel = React.memo(({ ordersKeys = {}, orders = [], refProps }) => {
  function showNotification() {
    notification.success({
      message: 'Excel файл успішно завантажений',
      duration: 5,
    });
  }

  return (
    <Flex justify={'center'} width={'max-content'}>
      <Workbook
        filename="reports.xlsx"
        element={
          <button ref={refProps} hidden={true} style={{ width: '0' }}>
            test excel
          </button>
        }
        beforeDownload={showNotification}>
        <Workbook.Sheet data={orders} name="Sheet A">
          {orders.length > 0 &&
            Object.keys(orders[0]).map((key, i) => (
              <Workbook.Column key={i} label={ordersKeys[i]} value={key} />
            ))}
        </Workbook.Sheet>
      </Workbook>
    </Flex>
  );
});
