import React from 'react';

// Styled
import { ModalContent } from '../UsersTable/styles';

/**
 * Component
 */
export const DeleteUserModal = () => {

  return (
    <ModalContent>
      Ви впевнені що хочете видалити користувача? Цю дію буде неможливо скасувати!
    </ModalContent>
  );
};

export default DeleteUserModal;
