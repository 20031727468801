import React, { useState } from 'react';

// Containers
import { CreatePDF } from 'modules/main/containers/PdfCreator/CreatePDF';
import { BillsPopup } from '../BillsPopup/index';

// Styles
import { Contaiter, CustomeButton, ListContainer } from './styles';
import { ListItem } from './listItem';
import TitleSum from './infoSumCalculate';

// Components
import { Badge, Button } from 'antd';

const buttonStyle = {
  height: 'auto',
  margin: '0 auto',
  padding: '3px 5px',
  width: '100%',
};
export const Bills = ({ order }) => {
  const {
    bills = [],
    currency = 'грн.',
    amount_payable = 0,
    amount_payable_in_PLN = 0,
    createDate = {},
    conversion_rate = 1,
    order_id,
    syncBill_1c = false,
    payed = 0,
  } = order;
  const [show, setShow] = useState(false);
  const [added, setAdded] = useState(false);

  const [popupShow, setPopupShow] = useState(false);
  const price = order?.price_wiht_discount || order?.full_price;
  let payedAll = +payed;
  let count = syncBill_1c ? 0 : 1;
  let sumOrders = +amount_payable;
  let payedAllInCur = payed / conversion_rate || 0;
  bills.map((item) => {
    sumOrders += item?.summ;
    payedAll += item?.payed || 0;
    payedAllInCur += item?.payed / item?.summ_rate || 0;
    count += item?.sync_1c ? 0 : 1;
  });
  const firstBillDate = createDate?.d + '.' + createDate?.m + '.' + createDate?.y;

  const handleToggle = () => {
    setPopupShow(!popupShow);
    setAdded(false);
  };
  const handleCreateBill = (bill) => {
    bills.push({ ...bill, addNow: true });
    setAdded(true);
  };
  return (
    <>
      {popupShow ? (
        <BillsPopup
          added={added}
          handleToggle={handleToggle}
          handleCreateBill={handleCreateBill}
          order={order}
          sumOrders={sumOrders}
          programPrice={price}></BillsPopup>
      ) : (
        ''
      )}
      <Contaiter>
        <CustomeButton
          className={show ? 'open' : ''}
          disabled={order?.programType === 'Оплати в школу аттестати/сертифікати'}
          onClick={() => {
            setShow(!show);
          }}>
          <Badge count={count} offset={[8, -10]}>
            <div style={{ width: '280px' }}>
              Список рахунків ({show ? 'сховати' : 'показати'})
            </div>
          </Badge>
        </CustomeButton>

        <ListContainer className={show ? 'open' : ''}>
          <TitleSum
            sumOrders={sumOrders}
            programPrice={price}
            payedAll={payedAll}
            payedAllInCur={payedAllInCur}
            currency={currency}
          />
          <ListItem
            style={{ fontWeight: 700 }}
            num="№"
            date="Дата"
            summ={`Cума(${currency})`}
            summ_rate={conversion_rate === 1 ? 1 : 0}
            summ_in_UAH={'в злотих'}
            order="Рахунок (EUR)">
            Рахунок PLN
          </ListItem>
          <ListItem
            num="1"
            date={firstBillDate}
            summ={amount_payable}
            summ_rate={conversion_rate}
            summ_in_UAH={amount_payable_in_PLN}
            order={
              <CreatePDF
                style={buttonStyle}
                short
                type={order?.programType === 'Лицей Кипр' ? 'newBill' : 'bill'}
                order={order}
              />
            }>
            <CreatePDF
              style={buttonStyle}
              short
              isCurrencyPLN
              type={order?.programType === 'Лицей Кипр' ? 'newBill' : 'bill'}
              order={order}
            />
          </ListItem>
          {bills.length > 0 &&
            bills.map((item, index) => (
              <ListItem
                key={order_id + '_ListItem_' + index}
                addNow={item?.addNow || false}
                num={index + 2}
                date={item?.date || ''}
                summ={item?.summ || ''}
                payed={item?.payed || 0}
                summ_rate={item?.summ_rate || 1}
                summ_in_UAH={item?.summ_in_UAH || item?.summ || ''}
                text_1c={'рахунку'}
                status_1c={item?.sync_1c}
                order={
                  <CreatePDF
                    style={buttonStyle}
                    short
                    type={order?.programType === 'Лицей Кипр' ? 'newBill' : 'bill'}
                    order={order}
                  />
                }>
                <CreatePDF
                  style={buttonStyle}
                  short
                  type={order?.programType === 'Лицей Кипр' ? 'newBill' : 'bill'}
                  order={order}
                  bill={{ ...item, num: index + 2 }}
                />
              </ListItem>
            ))}
          <ListItem text_1c={null}>
            <Button onClick={handleToggle} style={buttonStyle}>
              +Новий
            </Button>
          </ListItem>
        </ListContainer>
      </Contaiter>
    </>
  );
};
