import React from 'react';

// Icons
import {
  UsergroupAddOutlined,
  IdcardOutlined,
  ScheduleOutlined,
  FileAddOutlined,
  ReconciliationOutlined,
  ProfileOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';

export default [
  {
    label: 'Профіль',
    Icon: () => <IdcardOutlined />,
    link: '/admin',
    access: [],
    sub: [],
  },
  {
    label: 'Користувачі',
    Icon: () => <UsergroupAddOutlined />,
    link: '/admin/users',
    access: ['admin'],
    sub: [],
  },
  // {
  //   label: 'Доп. Поля',
  //   Icon: () => <UsergroupAddOutlined />,
  //   link: '/admin/dop-users',
  //   access: ['admin'],
  //   sub: [],
  // },
  {
    label: 'Дати програм',
    Icon: () => <ProfileOutlined />,
    link: '/admin/dates',
    access: ['admin'],
    sub: [],
  },
  {
    label: 'Доп. Поля',
    Icon: () => <ReconciliationOutlined />,
    link: '/admin/details',
    access: ['admin'],
    sub: [],
  },
  {
    label: 'Замовлення',
    Icon: () => <ScheduleOutlined />,
    link: '/admin/orders',
    access: [],
    sub: [],
  },
  {
    label: 'Створити замовлення',
    Icon: () => <FileAddOutlined />,
    link: '/admin/createOrder',
    access: [],
    sub: [],
  },
  {
    label: 'Курс валют',
    Icon: () => <MoneyCollectOutlined />,
    link: '/admin/currency',
    access: ['admin', 'superadmin'],
    sub: [],
  },
];
