export const transformOrders = (orders = []) => {
  return [
    {
      0: '#',
      1: 'Main ID',
      2: 'Глобальний id в системі',
      3: 'Тип програми',
      4: 'Назва програми',
      5: 'Назва програми в Замовленні/Рахунку для клієнта',
      6: 'Код номенклатури',
      7: 'Спосіб оплати',
      8: 'Локація продажу',
      9: 'Локація навчання',

      // //////
      10: 'Повна вартість програми',
      11: 'Знижка',
      12: 'Вартість зі знижкою',
      13: 'Сума передплати',
      14: 'Валюта',
      15: 'Сума передплати в злотих',
      16: 'Конверсія',
      // /////////
      17: 'Менеджер',
      18: 'Договір оферти',
      // /////////
      19: 'П.І.Б клієнта',
      20: "Ім'я студента",
      21: 'Дата народження',
      22: 'ІНН',
      23: 'Адреса клієнта',
      24: 'Паспорт клієнта',
      25: 'Телефон клієнта',
      26: 'Email клієнта',
      27: 'Лінк на AMO CRM',
      28: 'Курс/Клас',
      /////////

      29: 'Номер групи навчання',
      30: 'Рівень англійської',
      31: 'Канал продажу',

      32: 'Навчальний заклад',
      33: 'Дата початку навчання за кордоном',
      34: 'Назва освітнього курсу',
      35: 'Прогресія після освітнього курсу',
      36: 'Країна/місто освітнього курсу(автоматично)',
      37: 'Деталі додаткових послуг',

      38: 'Банк (реквізити)',
      39: 'Деталі (реквізити)',
      40: 'ЕДРПОУ (реквізити)',
      41: 'IBAN (реквізити)',
      42: 'Одержувач (реквізити)',
      43: 'Посилання на підпис (реквізити)',

      44: 'Дата старту програми',
      45: 'Дата кінця програми',

      46: 'Графік оплати',
      47: 'Особливі побажання клієнта',
      48: 'Дата cтворення',
    },
    orders.map((order) => {
      return {
        //0
        order_id: order.order_id || '',
        //1
        main_id: order?.main_id || '',
        //2
        global_id: order.global_id || '',
        //3
        program_type: order.programType?.title || order.programType,
        //4
        program_name: order.programName?.title || order.programName,
        //5
        program_name_for_docs:
          order?.program_name_for_docs || order.programName?.program_name_for_docs || '',
        //6
        nomenclature_code: order?.code || order.programName?.code || '',
        //7
        paymant: order?.payment || order.location?.paymant || '',
        //8
        location: order?.location?.title || order?.location || '',
        //9
        educationLocation: order?.educationLocation || '',
        //10
        full_price: order.programName?.full_price || order.full_price || '',
        //11
        discount: order?.discount || '',
        //12
        price_wiht_discount: order?.price_wiht_discount || '',
        //13
        amount_payable:
          order?.amount_payable || order.programName?.prepayment_amount || '',
        //14
        currency: order.programName?.currency || order?.currency || '',
        //15
        amount_payable_in_PLN: order.amount_payable_in_PLN || '',
        //16
        conversion_rate: order.conversion_rate || '',
        //17
        manager: order.manager?.id
          ? order.manager?.firstName + ' ' + order.manager?.lastName
          : order.manager || '',
        //18
        offer_agreement: order?.offer_agreement_link || order?.offer_agreement || '',
        //19
        client_payer_name: order.client?.payer_name || '',
        //20
        client_student_name: order.client?.student_name || '',
        //21
        client_birth_date: order.client?.birth_date || '',
        //22
        client_payer_tax_ID: order.client?.payer_tax_ID || '',
        //23
        client_address: order.client?.address || '',
        //24
        client_passport: order.client?.passport || '',
        //25
        contact_phone: order.client?.contact_phone || '',
        //26
        email: order.client?.email || '',
        //27
        link_AMO_CRM: order.link_AMO_CRM || '',
        //28
        client_now_class_course: order.client?.now_class_course || '',

        //29
        ukraine_education_education_grup:
          order.ukraine_education?.education_group ||
          order.ukraine_education?.education_grup ||
          '',
        //30
        ukraine_education_english_level: order.ukraine_education?.english_level || '',
        //31
        ukraine_education_attraction_channel:
          order.ukraine_education?.attraction_channel || '',
        //32
        education_school: order.abroad_education?.school || '',
        //33
        education_date: order.abroad_education?.date || '',
        //34
        education_course: order.abroad_education?.course || '',
        //35
        education_course_progression: order.abroad_education?.course_progression || '',
        //36
        education_city: order.abroad_education?.city || '',
        //37
        education_details: order.abroad_education?.details || '',

        //38
        recipient_bank: order.recipient?.bank || '',
        //39
        recipient_details: order.recipient?.details || '',
        //40
        recipient_edrpou: order.recipient?.edrpou || '',
        //41
        recipient_iban: order.recipient?.iban || '',
        //42
        recipient_title: order.recipient?.name || order.recipient?.title || '',
        //43
        recipient_offer_agreement_link: order.recipient?.signature_link || '',

        //44
        program_start_date: order.program_start_date || '',
        //45
        program_end_date: order.program_end_date || '',

        //46
        shedule_payments_fields:
          (order.shedule_payments_fields || [])
            .map((x) =>
              x.summa && x.date
                ? `${x.date}: ${x.summa} ${
                    order.programName?.currency || order?.currency || ''
                  }`
                : false
            )
            .filter((x) => x)
            .join('. ') || '',
        //47
        special_wishes: order?.special_wishes || '',
        //48
        create_date: `${order?.createDate?.d}-${order?.createDate?.m}-${order?.createDate?.y}`,
      };
    }),
  ];
};
