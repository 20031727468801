const whitelist = [];

export const applyResetState = (dispatch = {}, customWhitelist = []) => {
  const currentWhiteList = Array.isArray(customWhitelist) ? customWhitelist : whitelist;

  Object.entries(dispatch).forEach(([modelName = '', model = {}]) => {
    if (currentWhiteList.includes(modelName)) return;
    const { resetState = () => {} } = model;
    resetState();
  });
};

export default applyResetState;
