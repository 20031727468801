import stores from 'plugins/localForage';

export const userData = {
  get get() {
    return stores.get('main').getItem('userData');
  },
  set set(data) {
    return stores.get('main').setItem('userData', data);
  },
};

export const userToken = {
  get get() {
    return stores?.get('main').getItem('token') ?? null;
  },
  set set(data) {
    return stores.get('main').setItem('token', data);
  },
};
export const refToken = {
  get get() {
    return stores?.get('main').getItem('ref') ?? null;
  },
  set set(data) {
    return stores.get('main').setItem('ref', data);
  },
};
