const chekData = [
  {
    programType: 'Літні навчальні програми',
    programName: 'Групові поїздки Groups',
    paymant: 'Оплата готівкою',
  },
  {
    programType: 'Літні навчальні програми',
    programName: 'Групові поїздки Groups - EUR',
    paymant: 'Оплата готівкою',
  },
];
export const ShowAgreement = ({ programType = '', programName = '', paymant = '' }) => {
  let res = false;
  chekData.forEach((item) => {
    if (
      item.programType === programType &&
      item.programName === programName &&
      item.paymant === paymant
    ) {
      res = true;
    }
  });
  return res;
};

export default ShowAgreement;
