import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';
//get getCurrencies
import requests from '../../../main/controllers/requests';
import TitleSum from '../Bills/infoSumCalculate';
// plugins
import { connect } from '~/plugins/redux';
//ui
import { Checkbox, Button, InputGroup, Title } from 'ui';
//utils
import transformToNum from 'utils/getNumVal';
import { CheckProgramName } from 'utils/checkProgramNames';
// Components
import { InputNumber, Date as DateField } from 'components/Fields';
//styles
import {
  Container,
  Close,
  CloseButton,
  TextContainer,
  TitleSucces,
  TitleError,
} from './styles';

// Model data
const getStateFromModel = ({
  adminOrders: { orders, loading },
  users: { userData },
}) => ({
  orders,
  userData,
  loading,
});
const getMethodsFromModel = ({ adminOrders: { addBill } }) => ({
  addBill,
});

export const BillsPopup = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  ({
    date,
    summ,
    summ_rate,
    summ_in_UAH,
    added,
    sumOrders,
    programPrice,
    order = {},
    loading = false,

    handleToggle = () => {},
    handleCreateBill = () => {},
    addBill = () => {},
    ...props
  }) => {
    const { order_id, programName, conversion_rate, currency, recipient } = order;
    const [hasChange, setHasChange] = useState(false);
    const [tSumm, setTSum] = useState(summ ? summ : null);
    const [tSumm_in_UAH, setTSum_in_UAH] = useState(summ_in_UAH ? summ_in_UAH : null);
    const [rate, setRate] = useState(summ_rate ? summ_rate : 1);
    const [useRecipient, setUseRecipient] = useState(false);
    const [recipientActual, setRecipientActual] = useState(null);
    const [newRecipient, setNewRecipient] = useState(null);
    const dateFormatList = ['DD-MM-YYYY', 'DD.MM.YYYY'];
    const [dateDefault, setDateDefault] = useState(
      date ? moment(date).format(dateFormatList[1]) : moment().format(dateFormatList[1])
    );
    const handleChangeDate = (e) => {
      setDateDefault(moment(e).format(dateFormatList[1]));
      setHasChange(true);
    };
    const handleChangeSumm = (e) => {
      const val = transformToNum(e);
      setTSum(val);
      setTSum_in_UAH(+(val * rate).toFixed(0));
      setHasChange(true);
    };
    const handleChangeSummInUAH = (e) => {
      const val = transformToNum(e);
      setTSum(+(val / rate).toFixed(2));
      setTSum_in_UAH(val);
      setHasChange(true);
    };
    const handleAddBill = async () => {
      const payload = {
        order_id,
        date: dateDefault,
        summ: tSumm,
        summ_rate: rate,
        summ_in_UAH: tSumm_in_UAH,
      };
      if (useRecipient) {
        const res = await addBill({ ...payload, recipient: newRecipient });
        if (res?.success) {
          handleCreateBill({ ...payload, sync_1c: true, recipient: newRecipient });
          setTSum(0);
          setTSum_in_UAH(0);
        }
      } else {
        const res = await addBill(payload);
        if (res?.success) {
          handleCreateBill({ ...payload, sync_1c: true });
          setTSum(0);
          setTSum_in_UAH(0);
        }
      }
    };
    useEffect(() => {
      async function getCurrencyRate() {
        const exchangeRates = await requests.getCurrencies();
        const rate = exchangeRates.find((val) => val.ccy === currency);
        if (rate?.sell) setRate(rate.sell);
      }
      async function getRecipient() {
        const res = await requests.getRecipient({
          program_type: order?.programType || '',
          nomenclature_1s: order?.programName || '',
          payment_type: order?.payment || '',
          location: order?.location?.title || order?.location || '',
        });
        setNewRecipient(res?.recipient);
        if (
          res?.recipient?.edrpou !== recipient?.edrpou ||
          res?.recipient?.iban !== recipient?.iban ||
          res?.recipient?.bank !== recipient?.bank ||
          res?.recipient?.name !== recipient?.name ||
          res?.recipient?.title !== recipient?.title ||
          res?.recipient?.details !== recipient?.details ||
          res?.recipient?.place !== recipient?.place ||
          res?.recipient?.type !== recipient?.type ||
          res?.recipient?.director !== recipient?.director
        ) {
          setRecipientActual(false);
          setUseRecipient(true);
        } else {
          setRecipientActual(true);
          setUseRecipient(false);
        }
      }
      if (CheckProgramName(programName)) {
        setRate(conversion_rate);
        setUseRecipient(false);
        setNewRecipient(false);
      } else {
        if (currency !== 'UAH' && rate === 1) getCurrencyRate();
        if (newRecipient === null) getRecipient();
      }
    }, []);
    return (
      <>
        <Close onClick={handleToggle} />
        <Container style={props?.style || {}}>
          <CloseButton onClick={handleToggle} />
          <Title>Замовлення №{order_id}</Title>
          <TitleSucces>
            {loading ? <SyncOutlined spin /> : ''}
            {added ? 'Додано' : ''}
          </TitleSucces>

          {newRecipient ? (
            <InputGroup>
              <TextContainer>
                {recipientActual ? (
                  <TitleSucces>=Реквізити виконавця актуальні=</TitleSucces>
                ) : (
                  <TitleError style={{ color: 'red' }}>
                    !-Реквізити НЕ співпадають з останіми даними-!
                  </TitleError>
                )}

                <span>{newRecipient?.name || newRecipient?.title || 'null'}, </span>
                <br />
                <span>IBAN {newRecipient?.iban || 'null'}, </span>
                <br />
                <span>
                  в {newRecipient?.bank}, {newRecipient?.place || 'null'},
                </span>
                <br />
                <span>код за ЄДРПОУ {newRecipient?.edrpou || 'null'}, </span>
                <br />
                <span>{newRecipient?.details || 'null'}</span>
                <br />
                {newRecipient?.director ? (
                  <span>Директор: {newRecipient?.director}</span>
                ) : (
                  ''
                )}
              </TextContainer>

              <Checkbox
                checked={useRecipient}
                onClick={() => {
                  setUseRecipient(!useRecipient);
                }}>
                Додати реквізити в новий рахунок
              </Checkbox>
            </InputGroup>
          ) : newRecipient === null ? (
            <SyncOutlined spin />
          ) : (
            ''
          )}
          <TitleSum sumOrders={sumOrders + tSumm} programPrice={programPrice} />
          <InputGroup>
            <DateField
              addonBefore="Дата"
              onChange={handleChangeDate}
              defaultValue={moment(dateDefault, dateFormatList[1])}
              format={dateFormatList[0]}
            />
            <InputNumber
              addonBefore={`Сума (${currency})`}
              onChange={handleChangeSumm}
              value={tSumm}
            />
            {currency !== 'UAH' ? (
              <InputNumber
                addonBefore={`Сума (PLN) курс: ${rate}`}
                onChange={handleChangeSummInUAH}
                value={tSumm_in_UAH}
              />
            ) : (
              ''
            )}
          </InputGroup>
          <span style={{ width: '100px' }} {...props}>
            {props.children}
          </span>
          <div style={{ marginTop: 20 }}>
            <Button disabled={!hasChange || !tSumm || added} onClick={handleAddBill}>
              Додати рахунок
            </Button>
          </div>
        </Container>
      </>
    );
  }
);
