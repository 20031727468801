import styled from 'styled-components';
import { Title as title } from 'ui';

export const container = (Flex) => styled(Flex)`
  padding: 0 5% 0 5%;
`;

export const OrderForm = styled.form`
  max-width: 800px;
  width: 100%;
  padding: 2%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 50px;
  text-align: left;
`;

export const Title = styled(title)`
  margin: 40px 0 20px 0;
`;
