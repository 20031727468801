import { Provider, connect as _connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';

export const reducers = {};

export const initStore = () =>
  createStore(
    combineReducers(reducers),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export const connect = _connect;

export default {
  Provider,
};
