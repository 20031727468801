import styled from 'styled-components';

export const App = styled.div`
  width: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Main = styled.main`
  max-width: 100%;
`;
