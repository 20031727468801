import config from './config.json';
import { stores } from '../localForage';
import { RequestError } from 'utils';

const { apiDevURL, apiProdURL, apiVersion } = config;
const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];

if (LOCAL_DOMAINS.includes((window || { location: {} }).location.hostname)) {
  // URL = `http://localhost:${config.API_PORT}`;
}

export class API {
  constructor(entity = '', url = '') {
    if (LOCAL_DOMAINS.includes((global.window || { location: {} }).location.hostname)) {
      url = apiDevURL;
    } else {
      url = window?.location?.origin || apiProdURL;
    }

    this.URL = `${url}${apiVersion ? '/' : ''}${apiVersion}/${entity}`; //?
  }

  dataToURL = (data = {}) => {
    return Object.keys(data)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
      })
      .join('&');
  };

  request = async ({ url, method, isAuth, body, type }) => {
    const headers = {};
    const options = { method };
    const token = (await stores.get('main').getItem('token')) || '';

    if (isAuth && !token) {
      throw new RequestError({
        statusCode: 401,
        message: `The request was not sent. Missing token for authorization.`,
      });
    }

    if (isAuth) headers.authorization = `Bearer ${token}`;
    if (type === 'files' && body) options.body = body;
    if (type !== 'files' && body) options.body = JSON.stringify(body);
    if (type !== 'files') headers['content-type'] = 'application/json';

    options.headers = headers;

    return fetch(url, options)
      .then((res) => res.json())
      .catch(() => {
        throw new Error('Cannot connect to server');
      });
  };

  bodyToMultipart = (body, fileNames = []) => {
    const originalBody = Array.isArray(body) ? body : [];
    const result = new FormData();

    fileNames.forEach((fileName = '', index) => {
      result.append(fileName, originalBody[index]);
    });

    return result;
  };

  get = (path = '', isAuth = false, queries) => {
    return this.request({
      url: `${this.URL}/${path}${queries ? '?' + this.dataToURL(queries) : ''}`,
      method: 'GET',
      isAuth,
    });
  };

  post = (path = '', isAuth = false, body = {}, options) => {
    const isTypeFile = options && options.type === 'files';

    if (isTypeFile)
      body = this.bodyToMultipart(body, options.fileNames, options.textFields);

    return this.request({
      url: `${this.URL}/${path}`,
      method: 'POST',
      isAuth,
      body,
      type: options?.type,
    });
  };

  put = (path = '', isAuth = false, body = {}) => {
    return this.request({
      url: `${this.URL}/${path}`,
      method: 'PUT',
      isAuth,
      body,
    });
  };

  patch = (path = '', isAuth = false, body = {}) => {
    return this.request({
      url: `${this.URL}/${path}`,
      method: 'PATCH',
      isAuth,
      body,
    });
  };

  delete = (path = '', isAuth = false, body = {}) => {
    return this.request({
      url: `${this.URL}/${path}`,
      method: 'DELETE',
      isAuth,
      body,
    });
  };
}
