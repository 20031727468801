import React from 'react';

// Containers
import { CreatePDF } from 'modules/main/containers/PdfCreator/CreatePDF';
import { Bills } from '../Bills/index';
import { CopyOrder } from '../CopyOrder/index';
import { MarkToDelete } from '../MarkToDelete/index';
import { Freeze } from '../Freeze/index';
import { Surcharge } from '../Surcharge';

// Components
import { Flex } from '~/ui';
import { Table } from 'antd';

const { Column } = Table;

const resizedKeys = ['global_id', 'order_id'];
const resizedKeysButtons = ['BILL', 'BILLS'];
const title = {
  BILL: 'Рахунок',
  BILLS: 'Рахунок',
  COPYORDER: 'Коп. в нове замовлення',
  SURCHARGE: 'Доплата на замовлення',
  FREEZE: 'Заморозити суму',
  TODELETE: 'Відмітити на видалення',
};

const buttonStyle = {
  height: '34px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0',
  padding: '5px 10px',
};

// Header
export const OrdersTable = React.memo(
  ({
    pagination = {},
    loading = false,
    ordersKeys = {},
    orders = [],
    pureOrders = [],
    role,
    handlePagination = () => {},
  }) => {
    return (
      <Flex margin="0 auto" width="calc(100% - 40px)">
        <Table
          onChange={(e) => {
            handlePagination(e);
          }}
          pagination={pagination}
          loading={loading}
          className={'ordersCustome'}
          scroll={{ x: 15000, y: 500 }}
          rowClassName={(item, index) => {
            let className = '';
            if (pureOrders[index]?.freez) className += 'freezen ';
            return className;
          }}
          dataSource={orders.map((o, index) => ({
            BILL: (
              <CreatePDF
                short
                style={buttonStyle}
                type={pureOrders[index]?.programType === 'ІТ напрям' ? 'newBill' : 'bill'}
                order={pureOrders[index]}
                key={'bill_' + pureOrders[index]?.order_id}
              />
            ),
            BILLS: (
              <Bills
                order={pureOrders[index]}
                role={role}
                key={'bills_' + pureOrders[index]?.order_id}
              />
            ),
            COPYORDER: (
              <CopyOrder
                order={pureOrders[index]}
                key={'copy_order_' + pureOrders[index]?.order_id}
              />
            ),
            SURCHARGE: (
              <Surcharge
                order={pureOrders[index]}
                key={'surcharge_' + pureOrders[index]?.order_id}
              />
            ),
            FREEZE: (
              <Freeze
                order={pureOrders[index]}
                key={'freeze' + pureOrders[index]?.order_id}
              />
            ),
            TODELETE: (
              <MarkToDelete
                style={buttonStyle}
                order={pureOrders[index]}
                key={'mark_to_delete' + pureOrders[index]?.order_id}
              />
            ),
            ...o,
          }))}>
          {orders.length > 0 &&
            Object.keys({
              BILLS: 0,
              COPYORDER: 1,
              SURCHARGE: 2,
              FREEZE: 3,
              TODELETE: 4,
              ...orders[0],
            }).map((key, i) =>
              key !== 'BILL' &&
              key !== 'BILLS' &&
              key !== 'COPYORDER' &&
              key !== 'SURCHARGE' &&
              key !== 'FREEZE' &&
              key !== 'TODELETE' ? (
                <Column
                  {...{
                    width: resizedKeys.includes(key)
                      ? 100
                      : resizedKeysButtons.includes(key)
                      ? 185
                      : 'auto',
                  }}
                  ellipsis={true}
                  title={ordersKeys[i - 5]}
                  dataIndex={key}
                  key={key}
                />
              ) : key === 'CONTRACT' && role !== 'admin' ? null : (
                <Column
                  width={key === 'BILLS' ? '332px' : '130px'}
                  dataIndex={key}
                  key={key}
                  title={title[key] || ''}
                />
              )
            )}
        </Table>
      </Flex>
    );
  }
);

export default OrdersTable;
