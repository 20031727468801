import styled from 'styled-components';

export const ActionButtonsSpan = styled.span`
  .ActionButtonAdd,
  .ActionButtonEdit,
  .ActionButtonDelete {
    max-width: 50px;
    span {
      color: #000;
    }
  }

  .ActionButtonAdd {
    background: #dff0d8 !important;
    &:hover {
      background: #49c0b6 !important;
    }
  }

  .ActionButtonEdit {
    background: #c1f1fc !important;
    &:hover {
      background: #61b3de !important;
    }
  }

  .ActionButtonDelete {
    background: #ffaaaa !important;
    &:hover {
      background: #ff2d37 !important;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 2% 0 2% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  & > div {
    margin-top: 30px;
    width: 40%;
  }

  .Field {
    margin: 10px;
  }
`;
