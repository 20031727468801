export function checkPath(path, strict, router) {
  if (strict) return router ? router.pathname === path : false;
  return router ? router.pathname.includes(path) : false;
}

export const pathChecks = {
  isProfilePath: (router) => checkPath('profile', false, router),
  isAdminPath: (router) => checkPath('admin', false, router),
  isErrorPath: (router) => checkPath('_error', false, router),
};

export default checkPath;
