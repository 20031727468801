import { users, program_country, program_school } from '../services/request';
import { RequestError } from 'utils';

export const requests = {
  async getAllCountrys(payload) {
    const response = await program_country.get('all', true, payload);
    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async getAllSchools(payload) {
    const response = await program_school.get('all', true, payload);
    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async getAllUsers(payload) {
    const response = await users.get('all', true, payload);
    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async addCountryToServer(payload) {
    const response = await program_country.post('', true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async editCountryToServer(payload) {
    const response = await program_country.patch(``, true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async removeCountryFromServer(_id) {
    const response = await program_country.delete(_id, true, { _id });

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async addSchoolToServer(payload) {
    const response = await program_school.post('', true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async editSchoolToServer(payload) {
    const response = await program_school.patch(``, true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async removeSchoolFromServer(_id) {
    const response = await program_school.delete(_id, true, { _id });

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
};

export default requests;
