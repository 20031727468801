import { useCallback } from 'react';
import getValueFromEvent from './getValueFromEvent';

export const useHandleFields = (setState = () => {}) => {
  return useCallback(
    (key) => (e) => {
      const deep = key.split('.');
      const value = getValueFromEvent(e);

      if (deep.length > 1) {
        const [fKey, sKey] = deep;
        setState((state) => ({
          [fKey]: { ...(state[fKey] || {}), [sKey]: value },
        }));
        return;
      }
      setState({ [key]: value });
    },
    [setState]
  );
};

export default useHandleFields;
