// Pages
import App from './App';
import { MainForm } from './modules/main/containers/PaymentForm';
import { DownloadOrders } from './modules/admin-orders/pages/DownloadOrders';
import { MainPage } from './modules/main/pages/Main';
import { AdminProfile } from './modules/admin-profile/pages/AdminProfile';
import { AdminUsers } from './modules/admin-users/pages/AdminUsers';
import { AdminProgramDetails } from './modules/admin-program-details/pages/AdminProgramDetails';
import { AdminProgramDates } from './modules/admin-program-dates/pages/AdminProgramDates';

// Containers
import { AppWrapper } from './modules/_app/containers/AppWrapper';

// Plugins
import { initRoutes, addToRoutes } from './plugins/router';
import { PageCurrency } from './modules/admin-currency';

addToRoutes('/v1', App);
addToRoutes('/', MainPage);
addToRoutes('/signin', MainPage);
addToRoutes('/admin', AdminProfile);
addToRoutes('/admin/users', AdminUsers);

addToRoutes('/admin/details', AdminProgramDetails);
addToRoutes('/admin/dates', AdminProgramDates);

addToRoutes('/admin/orders', DownloadOrders);
addToRoutes('/admin/createOrder', MainForm);
addToRoutes('/admin/currency', PageCurrency);

// Init routes
export const Router = initRoutes({
  RouterWrapper: AppWrapper,
});
