import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .Field {
    margin: 10px;
  }
`;

export const inputHeight = (field, height = 'auto') => styled(field)`
  max-width: 400px;
  [class='ant-input-number-input-wrap'],
  [class='ant-picker-input-placeholder'],
  [class='ant-input'],
  [class='ant-picker-input'] {
    height: ${height};
    line-height: ${height};
  }
`;
