import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import { Input, InputPassword, Button, Text, Flex } from 'ui';

// Icons
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

// Styled
import { Container } from './styles';

const components = {
  input: Input,
  password: InputPassword,
};

const inputText = {
  firstName: "Ім'я",
  lastName: 'Прізвище',
  email: 'Пошта',
  password: 'Пароль',
  newPassword: 'Новий пароль',
};

// Redux
const getDataFromStore = ({ users: { userData, avatar } }) => ({
  userData,
  avatar,
});
const getMethodsFromStore = ({ users: { editProfileInfo, getProfileInfo } }) => ({
  editProfileInfo,
  getProfileInfo,
});

/**
 * Component
 */
export const UserProfile = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ userData = {}, getProfileInfo = () => {}, editProfileInfo = () => {} }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [prevLocalData, setPrevLocalData] = useState({});
  const [localData, setLocalData] = useState({});
  const updateField = (fieldName) => (e) => {
    setLocalData({
      ...localData,
      [fieldName]: e.target ? e.target.value : e,
    });
  };

  useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  const inputFullName = (name, type = 'input') => {
    const InputType = components[type];
    if (!InputType) return;
    const _placeholder = inputText[name] || '';
    return (
      <div>
        <div>{_placeholder ? `${_placeholder}:` : ''}</div>
        <InputType
          placeholder={_placeholder}
          id={name}
          defaultValue={userData[name]}
          value={localData[name]}
          onChange={updateField(name)}
        />
      </div>
    );
  };

  const handleEditButtonClick = () => {
    if (isEditMode) {
      editProfileInfo(localData);
      setEditMode(false);
    } else {
      setEditMode(true);
      setPrevLocalData(localData);
    }
  };

  const handleCloseEditWithoutChanges = () => {
    setEditMode(false);
    setLocalData(prevLocalData);
  };

  return (
    <Container direction="row" width="100%" wrap>
      <Flex className="UserContainer">
        <Flex className="InfoContainer" direction="row" wrap>
          <Flex direction="column" className="TextContainer" textAlign="left">
            {isEditMode ? (
              <>
                {inputFullName('firstName')}
                {inputFullName('lastName')}
                {inputFullName('email')}
                <br />
                {inputFullName('password', 'password')}
              </>
            ) : (
              <>
                <Text>Ім'я: {userData.firstName || ''}</Text>
                <Text>Прізвище: {userData.lastName || ''}</Text>
                <Text>Пошта: {userData.email || ''}</Text>
              </>
            )}
          </Flex>

          <Flex className="EditButtonsContainer" direction="row">
            <Button hidden={!isEditMode} onClick={handleCloseEditWithoutChanges}>
              <CloseOutlined className="Icon" />
            </Button>

            <Button onClick={handleEditButtonClick}>
              {isEditMode ? (
                <SaveOutlined className="Icon" />
              ) : (
                <EditOutlined className="Icon" />
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
});

export default UserProfile;
