import React from 'react';
import styled from 'styled-components';

const s = (tooltip) => styled(tooltip)`
  text-align: center;
`;

export default (tooltip) => {
  const Tooltip = s(tooltip);

  return (p) => <Tooltip {...p}>{p.children}</Tooltip>;
};
