import React from 'react';

export default ({ Actions, AddSchoolButton }) => [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    ellipsis: true,
    width: '100px',
  },
  {
    title: 'Школа',
    dataIndex: 'school',
    key: 'school',
    // ellipsis: true,
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: 130,
    render: (data, record) => <Actions record={record} />,
    title: () => <AddSchoolButton />,
  },
];
