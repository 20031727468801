import React from 'react';
import styled from 'styled-components';

const s = (textArea) => styled(textArea)``;

export default (textArea) => {
  const TextArea = s(textArea);

  return (p) => <TextArea {...p} />;
};
