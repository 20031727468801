import React from 'react';

import { Flex } from 'ui';

// Component
export const Header = () => {
  return (
    <Flex margin="20px auto 0 auto" width="50%">
      <Flex>
        <img src="/images/british_camp.jpg" width="200" alt="logo" />
      </Flex>
    </Flex>
  );
};
