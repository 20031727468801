import styled from 'styled-components';

export const container = (Flex) => styled(Flex)`
  padding: 10px;
  margin: 0 20px;
  width: calc(100% - 40px);
  border: 1px solid #ccc;
  border-radius: 5px;
  & > form > div > div {
    margin: 4px 0;
  }
`;
export const inputHeight = (field, height = 'auto') => styled(field)`
  [class='ant-input-number-input-wrap'],
  [class='ant-picker-input-placeholder'],
  [class='ant-input'],
  [class='ant-picker-input'] {
    height: ${height};
    line-height: ${height};
  }
`;
