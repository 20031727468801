import React from 'react';
import styled from 'styled-components';

export default (table) => {
  const Table = styled(table)`
    --header-background: #f5f9fb;
    --header-color: #000;
    --row-background: #eff3f8;
    --row-color: #1f283b;
    --footer-background: #f5f9fb;
    --footer-color: #eff3f8;
    --row-hover-background: #e4e2e9;
    --th-hover-background: #e4e2e9;
    --border-color: #e1e1e1;
    [class='ant-table-thead'] > tr > th,
    [class='ant-table-tbody'] > tr > td,
    [class='ant-table-container'] {
      border-color: var(--border-color) !important;
    }

    thead {
      tr {
        background: #fff;
        th {
          background: var(--header-background);
          color: var(--header-color);
          &:hover {
            background: var(--th-hover-background) !important;
          }
        }
      }

      @media screen and (max-width: 768px) {
        position: absolute;
        width: auto;
        tr {
          position: absolute;
          z-index: 9;
          th {
            width: auto;
          }
        }
      }
    }

    tbody {
      tr,
      [class='ant-table-expanded-row'],
      [class='ant-table-cell'] {
        background: var(--row-background);
        color: var(--row-color);

        &:hover > td {
          background: var(--row-hover-background) !important;
        }
      }

      @media screen and (max-width: 768px) {
        display: flex;
        min-width: 90vw;
        overflow-x: auto;
        tr {
          width: 100%;
          margin: 0 40vw;
        }
      }
    }

    tr {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    table {
      thead {
        tr {
          background: #fff;
          th {
            background: var(--header-background);
            color: var(--header-color);
            &:hover {
              background: var(--th-hover-background) !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          position: absolute;
          width: auto;
          tr {
            position: absolute;
            z-index: 9;
            th {
              width: auto;
            }
          }
        }
      }
    }

    [class='ant-table-title'] {
      padding: 3px 16px;
    }

    [class='ant-table-content'] {
      overflow-x: hidden;
    }

    [class*='ant-table-wrapper'] {
      background: var(--footer-background);
      color: var(--footer-color);
    }

    [class*='ant-table-row-expand-icon'] {
      margin: 25% !important;
    }

    [class='ant-table-thead'] > tr > th,
    [class='ant-table-tbody'] > tr > td,
    [class='ant-table'] tfoot > tr > th,
    [class='ant-table'] tfoot > tr > td {
      padding: 8px 6px !important;
      min-width: 60px !important;
      text-align: center;
      max-height: 60px !important;
      font-size: 14px !important;
    }

    [class='ant-table-column-sorters'] {
      padding: 0 !important;
      margin: 0 !important;
      max-height: 60px !important;
      span {
        max-height: 55px !important;
        overflow: hidden;
      }
    }

    [class='ant-table-column-sorters ant-tooltip-open'] {
      padding: 0;
    }
  `;

  return (p) => <Table {...p}>{p.children}</Table>;
};
