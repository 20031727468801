import isPlainObject from './isPlainObject';

let tokenRefreshRequest = null;

const onStatusCode = {
  // Unauthorized
  async 401({ dispatch = {}, retry = () => {}, params = {} } = {}) {
    const users = dispatch?.users || {};
    const { refreshToken = () => {} } = users;

    let token = '';
    if (tokenRefreshRequest) {
      token = await tokenRefreshRequest;
      tokenRefreshRequest = null;
    } else {
      tokenRefreshRequest = refreshToken();
      token = await tokenRefreshRequest;
      tokenRefreshRequest = null;
    }

    if (token) {
      if (isPlainObject(params) && params.hasOwnProperty('accessToken')) {
        params.accessToken = token;
      }

      await retry(params);
    }
  },
};

export function handleRequestError(
  params = {
    error: {},
    dispatch: {},
    rootState: {},
    retry: () => {},
    params: {},
  }
) {
  const { error = {} } = params;
  const { statusCode, message = '' } = error;
  console.log(message);

  const codeHandler = onStatusCode[statusCode];

  if (codeHandler) codeHandler(params);
}

export default handleRequestError;
