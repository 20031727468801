import React from 'react';
import { connect } from 'react-redux';

// Components
import { Button } from 'ui';

// Styled
import { ActionButtonsSpan } from './styles';

// Icons
import { PlusCircleOutlined } from '@ant-design/icons';

// Redux
const getDataFromStore = ({ adminUsers: { users } }) => ({
  users,
});
const getMethodsFromStore = ({
  adminUsers: { getAllUsers, setModal, setCurrentRecord },
}) => ({
  getAllUsers,
  setModal,
  setCurrentRecord,
});

export const AddUserButton = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ setModal = () => {}, setCurrentRecord = () => {} }) => {
  const handleOpenModal = (type) => () => {
    setModal(type);

    setCurrentRecord({});
  };

  return (
    <ActionButtonsSpan>
      <Button className="ActionButtonAdd" onClick={handleOpenModal('add')}>
        <PlusCircleOutlined />
      </Button>
    </ActionButtonsSpan>
  );
});

export default AddUserButton;
