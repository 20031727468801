import styled from 'styled-components';

export const container = (Flex) => styled(Flex)`
  padding: 0 5% 0 5%;
`;

export const ClientForm = styled.form`
  max-width: 800px;
  width: 100%;
  padding: 2%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 50px;
  text-align: left;
`;

export const Wrap = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 16px;
`;
export const Text = styled.p`
  margin: 0;
  font-weight: 600;
`;

export const inputHeight = (field, height = 'auto') => styled(field)`
  [class='ant-input-number-input-wrap'],
  [class='ant-picker-input-placeholder'],
  [class='ant-input'],
  [class='ant-picker-input'] {
    height: ${height};
    line-height: ${height};
  }
`;
