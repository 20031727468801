import { setState } from 'utils';

export const model = {
  state: {
    history: {},
    sideMenuWidth: '180px',
  },
  reducers: {
    setState,
    resetState(state) {
      const { history = {} } = state;
      return { history, sideMenuWidth: 0 };
    },
  },
  effects: () => ({}),
};

export default model;
