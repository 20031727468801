import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import LiberationSerifItalick from './fonts/LiberationSerif-Italic.ttf';

import { trasformData } from 'utils/restructuringOrderData';

Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifItalick,
  fontStyle: 'italic',
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'LiberationSerif',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 5,
  },
});

export const Order = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View>
            {orderValues.payment !== 'Оплата готівкою' ? (
              <View>
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: 12,
                    marginTop: 15,
                    marginBottom: 30,
                  }}>
                  <View style={{ width: 150, flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', marginRight: 5 }}>
                      Paragon numer:{' '}
                    </Text>
                    <Text>{orderValues.order_num}</Text>
                  </View>
                  <View style={{ width: 150, height: 150 }}>
                    <Image src="/images/british_camp.jpg" />
                  </View>
                </View>
                <View style={{ marginBottom: 20 }}>
                  <View style={styles.row_mb}>
                    <Text style={{ fontWeight: 'bold' }}>Data wystawienia: {'  '}</Text>
                    <Text>{orderValues.date}</Text>
                  </View>
                  <View style={styles.row_mb}>
                    <Text style={{ fontWeight: 'bold' }}>Data sprzedaży: {'  '}</Text>
                    <Text>{orderValues.date}</Text>
                  </View>
                  <View style={styles.row_mb}>
                    <Text style={{ fontWeight: 'bold' }}>Płatność: {'  '}</Text>
                    <Text>przelew</Text>
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 15,
                  }}>
                  <View style={{ width: '200px' }}>
                    <Text style={{ fontWeight: 'bold', marginBottom: 15 }}>
                      Sprzedawca
                    </Text>
                    <Text>{orderValues.recipient_name}</Text>
                    <Text>{orderValues.recipient_place}</Text>
                    <Text style={{ marginBottom: 15 }}>NIP: 5242956029</Text>
                  </View>
                  <View style={{ width: '200px' }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 15,
                      }}>
                      Nabywca
                    </Text>
                    <Text>{orderValues.client_payer_name}</Text>
                    <Text>{orderValues.client_address}</Text>
                  </View>
                </View>
                <Text>
                  KONTO: {orderValues.recipient_bank} {orderValues.recipient_edrpou},{' '}
                  {props.isCurrencyPLN
                    ? orderValues?.recipient_zloty_account
                    : orderValues?.recipient_euro_account}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>

          <View
            style={{
              flexDirection: 'row',
              marginTop: 20,
              textAlign: 'center',
              fontWeight: 'bold',
            }}>
            <Text style={{ border: 1, width: 40, padding: 10 }}>LP</Text>
            <Text
              style={{
                border: 1,
                width: 305,
                padding: 10,
                borderLeft: 0,
                fontWeight: 'bold',
              }}>
              Nazwa towaru/usługi
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: '3px 7px',
                borderLeft: 0,
                fontWeight: 'bold',
              }}>
              Ilość
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                padding: 10,
                borderLeft: 0,
                fontWeight: 'bold',
              }}>
              Cena
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                padding: 10,
                borderLeft: 0,
                fontWeight: 'bold',
              }}>
              Wartość brutto
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              textAlign: 'center',
            }}>
            <Text style={{ border: 1, width: 40, padding: 10, borderTop: 0 }}>1</Text>
            <Text
              style={{
                border: 1,
                width: 305,
                padding: 10,
                borderLeft: 0,
                borderTop: 0,
                textAlign: 'left',
              }}>
              {orderValues.program_name_for_docs}
              {(props.orderValues.programName ===
                'British Camp GREAT BRITAIN Queens college Summer 2023' ||
                props.orderValues.programName ===
                  'British Camp POLAND Warsaw Summer 2023') &&
                `, student ${orderValues.client_student_name}`}
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              1
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              {props.isCurrencyPLN
                ? (orderValues?.amount_payable * orderValues?.conversion_rate).toFixed(2)
                : orderValues?.amount_payable.toFixed(2)}
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              {props.isCurrencyPLN
                ? (orderValues?.amount_payable * orderValues?.conversion_rate).toFixed(2)
                : (orderValues?.amount_payable).toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              textAlign: 'center',
            }}>
            <Text
              style={{
                border: 1,
                width: 385,
                borderLeft: 1,
                borderTop: 0,
                borderBottom: 0,
                padding: '10px 2px',
              }}
            />
            <Text
              style={{
                border: 1,
                width: 80,
                borderLeft: 0,
                borderTop: 0,
                borderBottom: 1,
                padding: '10px 2px',
                fontWeight: 'bold',
              }}>
              W tym
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                borderLeft: 0,
                borderTop: 0,
                borderBottom: 1,
                padding: '10px 2px',
                fontWeight: 'bold',
              }}>
              {props.isCurrencyPLN
                ? (orderValues?.amount_payable * orderValues?.conversion_rate).toFixed(2)
                : (orderValues?.amount_payable).toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              marginBottom: 20,
              textAlign: 'center',
            }}>
            <Text
              style={{
                border: 1,
                width: 385,
                borderLeft: 1,
                borderTop: 0,
                borderBottom: 1,
                padding: '10px 2px',
              }}
            />
            <Text
              style={{
                border: 1,
                width: 80,
                borderLeft: 0,
                borderTop: 0,
                borderBottom: 1,
                padding: '10px 2px',
                fontWeight: 'bold',
              }}>
              Razem
            </Text>
            <Text
              style={{
                border: 1,
                width: 80,
                borderLeft: 0,
                borderTop: 0,
                borderBottom: 1,
                padding: '10px 2px',
                fontWeight: 'bold',
              }}>
              {props.isCurrencyPLN
                ? (orderValues?.amount_payable * orderValues?.conversion_rate).toFixed(2)
                : (orderValues?.amount_payable).toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              marginBottom: 10,
              justifyContent: 'flex-end',
            }}>
            <Text
              style={{
                fontWeight: 'bold',
              }}>
              Wartość brutto{' '}
            </Text>
            <Text>
              {props.isCurrencyPLN
                ? (orderValues?.amount_payable * orderValues?.conversion_rate).toFixed(2)
                : (orderValues?.amount_payable).toFixed(2)}{' '}
              {props.isCurrencyPLN ? 'PLN' : orderValues.currency}
            </Text>
          </View>
          <View style={{ width: '100%', flexDirection: 'row', marginBottom: 20 }}>
            <Text
              style={{
                fontWeight: 'bold',
              }}>
              Do zapłaty {'    '}
            </Text>
            <Text>
              {props.isCurrencyPLN
                ? (orderValues?.amount_payable * orderValues?.conversion_rate).toFixed(2)
                : (orderValues?.amount_payable).toFixed(2)}{' '}
              {props.isCurrencyPLN ? 'PLN' : orderValues.currency}
            </Text>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text
              style={{
                fontWeight: 'bold',
                width: 200,
              }}>
              Imię i nazwisko odbiorcy
            </Text>
            <Text
              style={{
                width: 200,
                fontWeight: 'bold',
              }}>
              Imię i nazwisko wystawcy
            </Text>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text
              style={{
                width: 200,
              }}>
              {orderValues.client_payer_name}
            </Text>
            <Text
              style={{
                width: 200,
              }}>
              BRITISH CAMP Spółka z ograniczoną odpowiedzialnością
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default Order;
