import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 5px;
  text-align: center;
  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }
  &.green {
    & > span > span {
      color: green;
    }
  }
`;
export default ({ sumOrders = 0, programPrice = 0 }) => {
  const left_pay = programPrice - sumOrders;
  return (
    <Container className={left_pay === 0 ? 'green' : ''}>
      <span>
        <b>Вартість:</b>
        <span>{programPrice}</span>
      </span>
      <span>
        <b>Cума рахунків:</b> <span>{+sumOrders.toFixed(2)}</span>
      </span>
    </Container>
  );
};
