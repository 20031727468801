import React from 'react';
import Form from 'react-bootstrap/Form';
class CustomSelect extends React.Component {
  render() {
    return (
      <Form.Control
        size="sm"
        as="select"
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}>
        <option value={0}>не выбрано...</option>
        {this.props.items.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title ? item.title : item.text ? item.text : item.nomenclature_1s}
          </option>
        ))}
      </Form.Control>
    );
  }
}
export default CustomSelect;
