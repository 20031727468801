import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  & > span {
    padding: 0 3px;
    font-weight: inherit;
  }
  &.addNow {
    background-color: #c4e8c4;
    padding-top: 5px;
    margin-top: -5px;
  }
`;
export const ListItem = ({
  addNow,
  num,
  date,
  summ,
  summ_rate = 1,
  summ_in_UAH = '',
  order = '',
  ...props
}) => {
  return (
    <Container className={addNow ? 'addNow' : ''} style={props?.style || {}}>
      <span style={{ width: '30px' }}>{num}</span>
      <span style={{ width: '85px' }}>{date}</span>
      <span style={{ width: '110px', lineHeight: '1.2' }}>
        {summ}{' '}
        {summ_rate !== 1 ? (
          <span style={{ whiteSpace: 'nowrap' }}>[{summ_in_UAH}]</span>
        ) : (
          ''
        )}
      </span>
      <span style={{ width: '100px' }}>{order}</span>
      <span style={{ width: '100px' }} {...props}>
        {props.children}
      </span>
    </Container>
  );
};
