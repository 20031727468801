import React from 'react';
import styled from 'styled-components';

// Styles
const s = (input) => styled(input)``;

// Component
export default (input) => {
  const Input = s(input);

  return (p) => {
    return <Input {...p} />;
  };
};
