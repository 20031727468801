import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Divider } from 'antd';
// UI
import { Flex, Title } from 'ui';

// Components
import {
  Date,
  Input,
  TextArea,
  InputNumber,
  SubmitButton,
  Label,
} from 'components/Fields';

// Utils
import { useHandleFields } from 'utils';

// Styled
import { container, inputHeight, ClientForm, Wrap, Text } from './styles';

const NumberField = inputHeight(InputNumber, '40px');
const TextField = inputHeight(Input, '40px');

const Container = container(Flex);

const getDataFromStore = ({ main: { selectedValues, client, orderCopy, main_id } }) => ({
  selectedValues,
  client,
  orderCopy,
  main_id,
});
const getMethodsFromStore = ({ main: { setState } }) => ({
  setState,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(({ selectedValues = {}, client = {}, main_id = '', setState = () => {}, ...props }) => {
  const { link_AMO_CRM } = selectedValues;

  const {
    payer_name = '',
    student_name = '',
    birth_date = '',
    payer_tax_ID = '',
    address = '',
    passport = '',
    contact_phone = '',
    email = '',
  } = client;
  const handleFields = useHandleFields(setState);

  // const handleCopyPayerName = () => {
  //   setState((state) => {
  //     const { client = {} } = state;
  //     const { payer_name = '' } = client;
  //     return {
  //       client: {
  //         ...client,
  //         student_name: payer_name,
  //       },
  //     };
  //   });
  // };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const [isDisabled, setIsDisabled] = useState({
    isPayerName: false,
    isStudentName: false,
  });
  const validateLatinInputPayer = (event) => {
    const { value } = event.target;
    const regex = /^[^а-яА-ЯёЁ]+$/;

    // Перевірка на відповідність регулярному виразу
    if (!regex.test(value)) {
      setIsDisabled((prevState) => ({ ...prevState, isPayerName: true }));
    }
  };
  const validateLatinInputStudent = (event) => {
    const { value } = event.target;
    const regex = /^[^а-яА-ЯёЁ]+$/;

    // Перевірка на відповідність регулярному виразу
    if (!regex.test(value)) {
      setIsDisabled((prevState) => ({ ...prevState, isStudentName: true }));
    }
  };
  const handleClearPayerNameInput = () => {
    setState((state) => {
      const { client = {} } = state;
      return {
        client: {
          ...client,
          payer_name: '',
        },
      };
    });
    setIsDisabled((prevState) => ({ ...prevState, isPayerName: false }));
  };
  const handleClearStudentNameInput = () => {
    setState((state) => {
      const { client = {} } = state;
      return {
        client: {
          ...client,
          student_name: '',
        },
      };
    });
    setIsDisabled((prevState) => ({ ...prevState, isStudentName: false }));
  };

  const message = 'Дозволені лише латинські або польські символи';

  return (
    <Container direction="column">
      <Title>Ідентифікація клієнта</Title>
      <ClientForm {...props}>
        <Divider orientation="center">Платник</Divider>
        {main_id && (
          <Wrap>
            <Text>{`Доплата для замовлення ${main_id}`}</Text>
          </Wrap>
        )}
        <Label required>
          <div style={{ display: 'flex' }}>
            <TextField
              disabled={isDisabled.isPayerName || main_id}
              value={payer_name}
              addonBefore="Платник ПІБ"
              onChange={handleFields('client.payer_name')}
              onInput={validateLatinInputPayer}
            />
            {isDisabled.isPayerName && (
              <button type="button" onClick={handleClearPayerNameInput}>
                Очистити
              </button>
            )}
          </div>
          {isDisabled.isPayerName && message}
        </Label>
        <Label>
          <TextField
            disabled={main_id}
            addonBefore="Контактний телефон:"
            value={contact_phone}
            onChange={handleFields('client.contact_phone')}
          />
        </Label>
        <Label>
          <TextField
            disabled={main_id}
            addonBefore="Email:"
            value={email}
            onChange={handleFields('client.email')}
          />
        </Label>

        <Label required>
          <NumberField
            disabled={main_id}
            addonBefore="ІНН платника"
            value={payer_tax_ID}
            onChange={handleFields('client.payer_tax_ID')}
          />
        </Label>
        <Label required>
          <TextField
            disabled={main_id}
            addonBefore="Фактична адреса проживання"
            value={address}
            onChange={handleFields('client.address')}
          />
        </Label>
        <Label required>
          <TextArea
            disabled={main_id}
            addonBefore="Дані паспорта платника"
            value={passport}
            onChange={handleFields('client.passport')}
          />
        </Label>
        <Divider orientation="center">Студент</Divider>
        <Label required>
          <div style={{ display: 'flex' }}>
            <TextField
              disabled={isDisabled.isStudentName || main_id}
              addonBefore="Студент ПІБ"
              // addonAfter={
              //   <Tooltip title="Скопіювати ПІБ платника">
              //     <Button type="link" onClick={handleCopyPayerName}>
              //       Той самий
              //     </Button>
              //   </Tooltip>
              // }
              value={student_name}
              onChange={handleFields('client.student_name')}
              onInput={validateLatinInputStudent}
            />
            {isDisabled.isStudentName && (
              <button type="button" onClick={handleClearStudentNameInput}>
                Очистити
              </button>
            )}
          </div>
        </Label>
        <Label required>
          <Date
            disabled={main_id}
            addonBefore="Дата народження"
            onChange={handleFields('client.birth_date')}
            format={'DD-MM-YYYY'}
            defaultValue={birth_date ? moment(birth_date, 'DD-MM-YYYY') : ''}
            disabledDate={disabledDate}
            value={birth_date ? moment(birth_date, 'DD-MM-YYYY') : ''}
          />
        </Label>
        <Divider />
        <Label required>
          <TextField
            disabled={main_id}
            addonBefore="Посилання на AMO CRM"
            value={link_AMO_CRM}
            onChange={handleFields('selectedValues.link_AMO_CRM')}
          />
        </Label>
        <div style={{ margin: '30px 0 20px 0' }}>
          <SubmitButton
            text="Далі >"
            disabled={
              !payer_name ||
              !student_name ||
              !birth_date ||
              !payer_tax_ID ||
              !address ||
              !passport ||
              !link_AMO_CRM
            }
          />
          {/* <Button type="primary" onClick={() => setState({ step: 1 })}>
            Назад
          </Button> */}
        </div>
      </ClientForm>
    </Container>
  );
});
