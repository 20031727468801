import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import LiberationSerifItalick from './fonts/LiberationSerif-Italic.ttf';

import { trasformData } from 'utils/restructuringOrderData';

Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifItalick,
  fontStyle: 'italic',
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'LiberationSerif',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
});

export const AccountCyprus = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);

  console.log('amoun_payable', orderValues.amount_payable);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View>
            <View
              style={{
                alignItems: 'flex-end',
                marginBottom: 30,
              }}>
              <Text
                style={{
                  width: '200',
                  padding: '3',
                  borderTop: 1,
                  borderLeft: 1,
                  borderRight: 1,
                }}>
                {`Invoice ${orderValues.order_num}`}
              </Text>
              <Text
                style={{
                  width: '200',
                  padding: '3',
                  border: 1,
                }}>
                {`Dated ${orderValues.date}`}
              </Text>
            </View>
            <View style={{ width: 400, alignSelf: 'flex-end' }}>
              <Text style={{ textDecoration: 'underline', marginBottom: 3 }}>
                Sent by:
              </Text>
              <View
                style={{
                  fontWeight: 'bold',
                  flexDirection: 'row',
                  marginBottom: 20,
                }}>
                <Text
                  style={{
                    width: 100,
                  }}>
                  Company Name:
                </Text>
                <Text
                  style={{
                    width: 280,
                  }}>
                  {orderValues.recipient_name}
                </Text>
              </View>
              <Text style={{ textDecoration: 'underline', marginBottom: 3 }}>
                Sent to:
              </Text>
              <View style={{ fontWeight: 'bold', flexDirection: 'row' }}>
                <Text
                  style={{
                    width: 100,
                    marginBottom: 3,
                  }}>
                  Customer Name:
                </Text>
                <Text>{orderValues.client_payer_name}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    width: 100,
                  }}>
                  Address:
                </Text>
                <Text
                  style={{
                    width: 200,
                  }}>
                  {orderValues.client_address}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                marginTop: 20,
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
              <Text style={{ border: 1, width: 355, padding: 10 }}>Details</Text>
              <Text
                style={{
                  borderTop: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '10px',
                }}>
                Amount
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 0,
                textAlign: 'center',
                marginBottom: 20,
              }}>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  borderLeft: 1,
                  width: 355,
                  padding: 10,
                  textAlign: 'left',
                }}>
                {orderValues.program_name_for_docs}
              </Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '10px 2px',
                }}>
                {orderValues?.amount_payable || 0} {orderValues.currency}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                }}>
                Please remit payments to:
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Beneficiary name:
                </Text>
                <Text
                  style={{
                    width: 200,
                  }}>
                  {orderValues.recipient_name}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  IBAN:
                </Text>
                <Text>CY82002001950000357037296711</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Account number:
                </Text>
                <Text>357037296711</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  SWIFT:
                </Text>
                <Text>BCYPCY2NXXX</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Bank name:
                </Text>
                <Text>{orderValues.recipient_bank}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Payments details:
                </Text>
                <Text>{`Education services according to invoice: ${orderValues.order_num} of ${orderValues.date}`}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default AccountCyprus;
