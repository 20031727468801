import localforage from 'localforage';

/**
 * await stores.get('main').getItem('token')
 * await stores.get('main').setItem('token', 12345)
 */
export const stores = new Map();

stores.set(
  'main',
  localforage.createInstance({
    name: 'STUDY_UA',
  })
);

export default stores;
