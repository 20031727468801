import React from 'react';

// Styled
import { ModalContent } from '../CountrysTable/styles';

/**
 * Component
 */
export const DeleteCountryModal = () => {
  return (
    <ModalContent>
      Ви впевнені що хочете видалити країну? Цю дію буде неможливо скасувати!
    </ModalContent>
  );
};

export default DeleteCountryModal;
