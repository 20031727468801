import React from 'react';
import styled from 'styled-components';

const s = (inputPassword) => styled(inputPassword)``;

export default (inputPassword) => {
  const InputPassword = s(inputPassword);

  return (p) => <InputPassword {...p} />;
};
