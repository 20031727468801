import React, { useState } from 'react';
import { connect } from 'react-redux';

// Components
import { Input, SubmitButton } from 'components/Fields';
// styles
import { Container, inputHeight } from './styles';
const TextField = inputHeight(Input, '40px');

const getDataFromStore = ({ adminUsers: { users } }) => ({ users });
const getMethodsFromStore = ({ adminUsers: { getAllUsers } }) => ({ getAllUsers });

export const SearchUsers = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  (
    // props
    { users = [], getAllUsers = () => {} }
  ) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const searchUser = async () => {
      const params = { firstName, lastName, email };
      getAllUsers(params);
    };
    return (
      <Container>
        <TextField
          addonBefore="Ім'я"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          addonBefore="Прізвище"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          addonBefore="Пошта"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <SubmitButton text="пошук" onClick={searchUser} />
      </Container>
    );
  }
);

export default SearchUsers;
