export const initialState = {
  loading: false,
  userData: {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
  users: [],
  roles: [],
  token: '',
  error: null,
  notify: null,
  modal: null,
};

export default initialState;
