import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import CustomSelect from './components/forms/CustomSelect';

// UI
import { Select, Flex } from '~/ui';

// Components
import Order from './components/pdf_creator/order';
import Account from './components/pdf_creator/account';
import { ManagersSelect } from './modules/catalogs/containers/ManagersSelect';

// API Requests
import {
  initLoadRequest,
  getProgramNames,
  getPaymant,
  getLocation,
  getRecipient,
  sentDate,
} from './API/getresponce';
import { getRates } from './API/exchange_rates';

import { englishLevels } from './modules/main/mocks/englishLevels';

// Styles
import './App.css';

/**
 * Main APP Component
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: {
        programType: '',
        programName: '',
        paymant: '',
        location: '',
        recipient: '',
        offer_agreement: '',
        program_start_date: '',
        program_end_date: '',
        discount: 0,
        amount_payable: '',
        payment_schedule: '',
        price_wiht_discount: '',
        price_in_UAH: '',
        date_create: '',
        time_create: '',
        unique_order_number: '',
        manager: '',
        link_AMO_CRM: '',
        conversion_rate: 1,
      },
      client: {
        payer_name: '',
        student_name: '',
        birth_date: '',
        payer_tax_ID: '',
        address: '',
        now_class_course: '',
        passport: '',
      },
      ukraine_education: {
        attraction_channel: '',
        english_level: '',
        education_grup: '',
      },
      abroad_education: {
        school: '',
        date: '',
        course: '',
        course_progression: '',
        city: '',
        details: '',
      },
      rateUSD: [],
      rateEUR: [],
      //-----------------------
      program_types: [],
      program_names: [],
      paymant: [],
      locations: [],
      step: 0,
      createOrderPDF: false,
      createAccountPDF: false,
      text: '',
      document_create: false,
    };
  }

  showOnlyExist = (type, value) => {
    if (type === 'array')
      return {
        ...((value || []).length > 0 ? { value } : {}),
      };
    if (type === 'input')
      return {
        ...(value ? { value } : {}),
      };
  };

  handleFields = (key) => (e) => {
    const deep = key.split('.');
    const value = e.target ? e.target.value : e;

    if (deep.length > 1) {
      const [fKey, sKey] = deep;
      this.setState({
        ...this.state,
        [fKey]: { ...this.state[fKey], [sKey]: value },
      });
      return;
    }
    this.setState({ ...this.state, [key]: value });
  };

  render() {
    return (
      <div className="App">
        <h2>Ласкаво просимо на портал оплати STUDY.UA</h2>
        {this.state.step < 6 ? (
          <Form className="form_wrap" name="block1" onSubmit={this.handleNexBlock}>
            <Form.Label>Тип програми:</Form.Label>
            <CustomSelect
              {...this.showOnlyExist('input', this.state.selectedValues.programType.id)}
              items={this.state.program_types}
              name={'program_type'}
              onChange={this.handleChangeProgramType}
              disabled={this.state.step <= 0}
            />
            <Form.Label>Назва програми:</Form.Label>
            <Select
              mode="multiple"
              name="program_name"
              defaultValue={'не выбрано...'}
              {...this.showOnlyExist('array', this.state.selectedValues.prname)}
              onChange={this.handleChangeProgramName}
              disabled={this.state.step <= 1}>
              {[{ title: 'не выбрано...' }]
                .concat(this.state.program_names || [])
                .filter((pn) => pn.title)
                .map((nm, key) => (
                  <option key={key} value={nm.title}>
                    {nm.title}
                  </option>
                ))}
            </Select>
            <Form.Label>Спосіб оплати:</Form.Label>
            <CustomSelect
              {...this.showOnlyExist('input', this.state.selectedValues.paymant.id)}
              items={this.state.paymant}
              name={'paymant'}
              onChange={this.handleChangePayment}
              disabled={this.state.step <= 2}
            />
            <Form.Label>Локація продажу:</Form.Label>
            <Select
              mode="multiple"
              name="location"
              defaultValue={'не выбрано...'}
              {...this.showOnlyExist('array', this.state.selectedValues.locs)}
              onChange={this.handleChangeLocation}
              disabled={this.state.step <= 3}>
              {[{ title: 'не выбрано...' }]
                .concat(this.state.locations || [])
                .map((loc, key) => (
                  <option key={key} value={loc.title}>
                    {loc.title}
                  </option>
                ))}
            </Select>
            <Form.Label>Локація навчання:</Form.Label>
            <Select
              mode="multiple"
              name="location"
              defaultValue={'не выбрано...'}
              {...this.showOnlyExist('array', this.state.selectedValues.locs_study)}
              onChange={this.handleChangeLocationStudy}
              disabled={this.state.step <= 3}>
              {[{ title: 'не выбрано...' }]
                .concat(this.state.locations || [])
                .map((loc, key) => (
                  <option key={key} value={loc.title}>
                    {loc.title}
                  </option>
                ))}
            </Select>
            <br />

            <ListGroup variant="flush">
              <ListGroup.Item>ДОДАТКОВА ІНФОРМАЦІЯ ПРО НАВЧАННЯ В УКРАЇНІ</ListGroup.Item>
              <ListGroup.Item>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="ukraine_education_grup">
                      Номер групи навчання
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist(
                      'input',
                      this.state.ukraine_education.education_grup
                    )}
                    aria-label="Small"
                    aria-describedby="ukraine_education_grup"
                    onChange={this.handleChangeEducationGrup}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="ukraine_education_english_level">
                      Рівень англійскої на сьогодні
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    size="sm"
                    as="select"
                    aria-label="Small"
                    aria-describedby="ukraine_education_english_level"
                    {...this.showOnlyExist(
                      'input',
                      this.state.ukraine_education.english_level
                    )}
                    onChange={this.handleChangeEnglishLevel}>
                    <option value={0}>не выбрано...</option>
                    {(englishLevels || []).map((item) => (
                      <option key={item.id} value={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </Form.Control>
                </InputGroup>
                <Form.Label>Канал продажу:</Form.Label>
                <InputGroup size="sm" className="mb-2">
                  <CustomSelect
                    {...this.showOnlyExist(
                      'input',
                      this.state.ukraine_education.attraction_channel
                    )}
                    items={[
                      { id: 'TMK', title: 'Маркетинг (ТМК)' },
                      { id: 'Visit', title: 'Візит в офіс' },
                      { id: 'Renew', title: 'Продовження (Renew)' },
                      { id: 'Ref', title: 'Рекомендація (Ref)' },
                      { id: 'Old', title: 'Клієнт із бази (Old)' },
                    ]}
                    name={'attraction_channel'}
                    onChange={this.handleChangeAttractionChannel}
                  />
                </InputGroup>
              </ListGroup.Item>
            </ListGroup>

            <ListGroup variant="flush">
              <ListGroup.Item>ДОДАТКОВА ІНФОРМАЦІЯ ПРО ОСВІТУ ЗА КОРДОНОМ</ListGroup.Item>
              <ListGroup.Item>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="abroad_education_school">
                      Навчальний заклад
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist('input', this.state.abroad_education.school)}
                    aria-label="Small"
                    aria-describedby="abroad_education_school"
                    name="abroad_education_school"
                    onChange={this.handleChangeAbroadEducationSchool}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="abroad_education_date">
                      Дата початку навчання за кордоном
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist(
                      'input',
                      (this.state.abroad_education.date || '')
                        .split('.')
                        .reverse()
                        .join('-')
                    )}
                    aria-label="Small"
                    aria-describedby="abroad_education_date"
                    name="abroad_education_date"
                    type="date"
                    onChange={this.handleChangeAbroadEducationDate}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="abroad_education_course">
                      Назва освітнього курсу
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist('input', this.state.abroad_education.course)}
                    aria-label="Small"
                    aria-describedby="abroad_education_course"
                    name="abroad_education_course"
                    onChange={this.handleChangeAbroadEducationCourse}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="abroad_education_course_progression">
                      Прогресія після освітнього курсу
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist(
                      'input',
                      this.state.abroad_education.course_progression
                    )}
                    aria-label="Small"
                    aria-describedby="abroad_education_course_progression"
                    name="abroad_education_course_progression"
                    onChange={this.handleChangeAbroadEducationCourseProgression}
                  />
                </InputGroup>
                <InputGroup size="sm" className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="abroad_education_city">
                      Країна/місто освітнього курсу(автоматично)
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist('input', this.state.abroad_education.city)}
                    aria-label="Small"
                    aria-describedby="abroad_education_city"
                    name="abroad_education_city"
                    onChange={this.handleChangeAbroadEducationCity}
                  />
                </InputGroup>
                <label>
                  Деталі додаткових послуг (якщо замовлені) - тип проживання, харчування,
                  страховка, трансфер і т.і.
                </label>
                <InputGroup size="sm">
                  <FormControl
                    {...this.showOnlyExist('input', this.state.abroad_education.details)}
                    as="textarea"
                    aria-label="With textarea"
                    name="abroad_education_details"
                    onChange={this.handleChangeAbroadEducationDetails}
                  />
                </InputGroup>
              </ListGroup.Item>
            </ListGroup>
            {this.state.step > 4 ? (
              <>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <b>Виконавець:</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {this.state.selectedValues.recipient.title}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Реквізити:</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {this.state.selectedValues.recipient.title},{' '}
                    {this.state.selectedValues.recipient.iban}, в{' '}
                    {this.state.selectedValues.recipient.bank}, код за ЄДРПОУ{' '}
                    {this.state.selectedValues.recipient.edrpou}{' '}
                    {this.state.selectedValues.recipient.details}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Button
                      variant="link"
                      target="_blank"
                      href={this.state.selectedValues.recipient.offer_agreement_link}>
                      договір оферти
                    </Button>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <InputGroup size="sm" className="mb-2 requiredFild">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Менеджер продажів</InputGroup.Text>
                      </InputGroup.Prepend>
                      <ManagersSelect
                        {...this.showOnlyExist(
                          'input',
                          this.state.selectedValues.manager
                        )}
                        onChange={this.handleChangeManager}
                      />
                      &nbsp;
                      <FormControl
                        {...this.showOnlyExist(
                          'input',
                          this.state.selectedValues.manager
                        )}
                        placeholder="Додати нового менеджера"
                        onChange={this.handleChangeManager}
                      />
                    </InputGroup>
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!this.state.selectedValues.manager}>
                  Далі
                </Button>
              </>
            ) : (
              ''
            )}
          </Form>
        ) : this.state.step < 7 ? (
          <>
            <h3>Ідентифікація клієнта</h3>
            <Form className="form_wrap" name="block2" onSubmit={this.handleNexBlock}>
              <InputGroup className="mb-3 requiredFild">
                <InputGroup.Prepend>
                  <InputGroup.Text>Платник ПІБ</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist('input', this.state.client.payer_name)}
                  name="payer_name"
                  onChange={this.handleChangeClient_payer_name}
                />
              </InputGroup>
              <div className="mb-3 requiredFild">
                <InputGroup.Text>Студент ПІБ </InputGroup.Text>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>той самий</InputGroup.Text>
                    <InputGroup.Checkbox
                      name="set_client_same_name"
                      onChange={this.handleSetClient_same_name}
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist('input', this.state.client.student_name)}
                    aria-label="Text input with checkbox"
                    onChange={this.handleChangeClient_student_name}
                    name="student_name"
                    // disabled={this.state.step < 7 ? true : false}
                  />
                </InputGroup>
              </div>
              <InputGroup className="mb-3 requiredFild">
                <InputGroup.Prepend>
                  <InputGroup.Text>Дата народження</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist(
                    'input',
                    (this.state.client.birth_date || '').split('.').reverse().join('-')
                  )}
                  aria-label="Text input with checkbox"
                  type="date"
                  name="birth_date"
                  onChange={this.handleChangeClient_birth_date}
                />
              </InputGroup>
              <InputGroup className="mb-3 requiredFild">
                <InputGroup.Prepend>
                  <InputGroup.Text>ІНН платника</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist('input', this.state.client.payer_tax_ID)}
                  aria-label="Text input with checkbox"
                  type="number"
                  name="payer_tax_ID"
                  onChange={this.handleChangeClient_payer_tax_ID}
                />
              </InputGroup>
              <InputGroup className="mb-3 requiredFild">
                <InputGroup.Prepend>
                  <InputGroup.Text>Посилання на AMO CRM</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist('input', this.state.selectedValues.link_AMO_CRM)}
                  name="link_AMO_CRM"
                  onChange={this.handleChangeClient_link_AMO_CRM}
                />
              </InputGroup>
              <InputGroup className="mb-3 requiredFild">
                <InputGroup.Prepend>
                  <InputGroup.Text>Адреса проживання фактична</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist('input', this.state.client.address)}
                  as="textarea"
                  aria-label="Text input with checkbox"
                  name="client_address"
                  onChange={this.handleChangeClient_address}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Клас/курс (на момент старту програми)</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist('input', this.state.client.now_class_course)}
                  aria-label="Text input with checkbox"
                  name="now_class_course"
                  onChange={this.handleChangeClient_now_class_course}
                />
              </InputGroup>
              <InputGroup className="mb-3 requiredFild">
                <InputGroup.Prepend>
                  <InputGroup.Text>Дані паспорта платника</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...this.showOnlyExist('input', this.state.client.passport)}
                  as="textarea"
                  aria-label="Text input with checkbox"
                  name="passport"
                  onChange={this.handleChangeClient_passport}
                />
              </InputGroup>
              <Button variant="primary" className="mr-3" onClick={this.handlePrevBlock}>
                Попередній крок
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !(
                    this.state.client.payer_name !== '' &&
                    this.state.client.student_name !== '' &&
                    this.state.client.birth_date !== '' &&
                    this.state.client.passport !== '' &&
                    this.state.client.payer_tax_ID !== '' &&
                    this.state.selectedValues.link_AMO_CRM !== '' &&
                    this.state.client.address !== ''
                  )
                }>
                Далі
              </Button>
            </Form>
          </>
        ) : (
          <>
            <h3>Вартість програми</h3>
            <Form className="form_wrap" name="block3" onSubmit={this.handleNexBlock}>
              <div className={this.state.document_create ? 'block_form_content' : ''}>
                <InputGroup className="mb-3 requiredFild">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Дата початку програми</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist(
                      'input',
                      (this.state.selectedValues.program_start_date || '')
                        .split('.')
                        .reverse()
                        .join('-')
                    )}
                    aria-label="Text input with checkbox"
                    type="date"
                    name="program_start_date"
                    onChange={this.handleChange_program_start_date}
                  />
                </InputGroup>
                <InputGroup className="mb-3 requiredFild">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Дата закінчення програми</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist(
                      'input',
                      (this.state.selectedValues.program_end_date || '')
                        .split('.')
                        .reverse()
                        .join('-')
                    )}
                    aria-label="Text input with checkbox"
                    type="date"
                    name="program_end_date"
                    onChange={this.handleChange_program_end_date}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Повна вартість програми</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    value={
                      this.state.selectedValues.programName.full_price +
                      ' ' +
                      this.state.selectedValues.programName.currency
                    }
                    readOnly
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      Сума знижки ({this.state.selectedValues.programName.currency})
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    {...this.showOnlyExist('input', this.state.selectedValues.discount)}
                    type="number"
                    name="discount"
                    // value={this.state.selectedValues.discount}
                    onChange={this.handleChange_discount}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Сума по договору зі знижкою</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    value={
                      this.state.selectedValues.price_wiht_discount +
                      ' ' +
                      this.state.selectedValues.programName.currency
                    }
                    name="price_wiht_discount"
                    readOnly
                  />
                </InputGroup>
                <InputGroup className="mb-3 requiredFild">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      Сума до сплати в рахунку в{' '}
                      {this.state.selectedValues.programName.currency}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    value={this.state.selectedValues.amount_payable}
                    name="amount_payable"
                    onChange={this.handleChange_amount_payable}
                  />
                </InputGroup>
                {this.state.selectedValues.programName.currency !== 'UAH' ? (
                  <div className="mb-3 requiredFild">
                    <InputGroup.Text>
                      Сума до сплати в рахунку з перерахуванням
                    </InputGroup.Text>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {this.state.selectedValues.programName.currency} в UAH по курсу{' '}
                          {this.state.selectedValues.conversion_rate}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        value={this.state.selectedValues.amount_payable_in_PLN}
                        name="amount_payable"
                        onChange={this.handleChange_amount_payable_in_PLN}
                      />
                    </InputGroup>
                  </div>
                ) : null}
                {(this.state.shedule_payments_fields || []).map((shedule, i) => {
                  if (i < 13) {
                    return (
                      <InputGroup className="mb-3 requiredFild">
                        <InputGroup.Prepend>
                          <InputGroup.Text>Графік платежів</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Flex style={{ width: '77%' }}>
                          <b style={{ width: '100px' }}>Дата:</b>
                          <FormControl
                            style={{ width: '50%' }}
                            type="date"
                            onChange={this.handleChange_payment_schedule_date(i)}
                            value={
                              this.state.selectedValues[`payment_schedule_date_${i}`] ||
                              ''
                            }
                            name="payment_schedule"
                          />
                          <b style={{ width: '100px' }}>Сумма</b>
                          <FormControl
                            // as="textarea"
                            style={{ width: '50%' }}
                            onChange={this.handleChange_payment_schedule(i)}
                            value={
                              this.state.selectedValues[`payment_schedule_${i}`] || ''
                            }
                            name="payment_schedule"
                          />
                        </Flex>
                      </InputGroup>
                    );
                  } else {
                    return '';
                  }
                })}

                <Button variant="primary" className="mr-3" onClick={this.handlePrevBlock}>
                  Назад
                </Button>
                <Button
                  variant="primary"
                  className="mr-3"
                  onClick={this.handleCreateDocument}
                  disabled={
                    !(
                      this.state.selectedValues.program_start_date !== '' &&
                      this.state.selectedValues.program_end_date !== '' &&
                      this.state.selectedValues.amount_payable !== '' &&
                      this.state.selectedValues.payment_schedule_0 &&
                      this.state.selectedValues.payment_schedule_date_0
                    )
                  }>
                  Створити документи
                </Button>
              </div>
              {this.state.document_create ? (
                <div>
                  <ListGroup.Item style={{ marginBottom: 20 }}>
                    Дата: {this.state.date_create}
                    {', час: '}
                    {this.state.time_create}, №{this.state.unique_order_number}
                  </ListGroup.Item>
                  <Button
                    className="mr-3"
                    variant="primary"
                    type="submit"
                    disabled={
                      !(
                        this.state.selectedValues.program_start_date !== '' &&
                        this.state.selectedValues.program_end_date !== '' &&
                        this.state.selectedValues.amount_payable !== ''
                      )
                    }
                    onClick={this.handleCreateOrderPDF}>
                    рахунок PDF
                  </Button>
                  <Button
                    className="mr-3"
                    variant="primary"
                    type="submit"
                    disabled={
                      !(
                        this.state.selectedValues.program_start_date !== '' &&
                        this.state.selectedValues.program_end_date !== '' &&
                        this.state.selectedValues.amount_payable !== ''
                      )
                    }
                    onClick={this.handleCreateAccountPDF}>
                    замовлення PDF
                  </Button>
                  <Button
                    className="mr-3"
                    variant="primary"
                    type="submit"
                    disabled={!(this.state.createAccountPDF && this.state.createOrderPDF)}
                    onClick={this.initFalues}>
                    Новий договір
                  </Button>
                </div>
              ) : null}
              {this.state.createAccountPDF || this.state.createOrderPDF ? (
                <div style={{ marginTop: 20 }}>
                  {this.state.createOrderPDF ? (
                    <PDFDownloadLink
                      document={<Order orderValues={this.state} />}
                      fileName={
                        'рахунок_' +
                        this.state.client.payer_name +
                        '_' +
                        this.state.selectedValues.programName.nomenclature_1s +
                        '.pdf'
                      }
                      style={{ marginRight: 40 }}>
                      {({ loading }) => (loading ? 'Loading document...' : 'Рахунок')}
                    </PDFDownloadLink>
                  ) : null}
                  {this.state.createAccountPDF ? (
                    <PDFDownloadLink
                      document={<Account orderValues={this.state} />}
                      fileName={
                        'Замовлення_' +
                        this.state.client.payer_name +
                        '_' +
                        this.state.selectedValues.programName.nomenclature_1s +
                        '.pdf'
                      }>
                      {({ loading }) => (loading ? 'Loading document...' : 'Замовлення')}
                    </PDFDownloadLink>
                  ) : null}
                </div>
              ) : (
                ''
              )}
            </Form>
          </>
        )}
      </div>
    );
  }

  async componentDidMount() {
    this.initFalues();
  }

  initFalues = async () => {
    const responce = await initLoadRequest();

    this.setState(() => ({
      program_types: responce,
      selectedValues: {
        programType: '',
        programName: '',
        paymant: '',
        location: '',
        recipient: '',
        offer_agreement: '',
        program_start_date: '',
        program_end_date: '',
        discount: 0,
        amount_payable: '',
        payment_schedule: '',
        price_wiht_discount: '',
        price_in_UAH: '',
        date_create: '',
        time_create: '',
        unique_order_number: '',
        manager: '',
        link_AMO_CRM: '',
        conversion_rate: 1,
      },
      client: {
        payer_name: '',
        student_name: '',
        birth_date: '',
        payer_tax_ID: '',
        address: '',
        now_class_course: '',
        passport: '',
      },
      ukraine_education: {
        attraction_channel: '',
        english_level: '',
        education_grup: '',
      },
      abroad_education: {
        school: '',
        date: '',
        course: '',
        course_progression: '',
        city: '',
        details: '',
      },
      rateUSD: [],
      rateEUR: [],
      //-----------------------
      program_names: [],
      paymant: [],
      locations: [],
      createOrderPDF: false,
      createAccountPDF: false,
      document_create: false,
      time_create: '',
      unique_order_number: '',
      date_create: '',
      text: '',
      step: 1,
      shedule_payments_fields: [0],
    }));
  };
  handleCreateOrderPDF = () => {
    if (!this.state.block_btn) {
      this.setState((state) => ({
        ...state,
        block_btn: true,
      }));
      setTimeout(() => {
        this.setState((state) => ({
          ...state,
          block_btn: false,
          createOrderPDF: true,
        }));
      }, 1500);
    }
    // console.log('OrderPDF');
    // console.log(this.state);
  };
  handleCreateAccountPDF = () => {
    if (!this.state.block_btn) {
      this.setState((state) => ({
        ...state,
        block_btn: true,
      }));
      setTimeout(() => {
        this.setState((state) => ({
          ...state,
          block_btn: false,
          createAccountPDF: true,
        }));
      }, 1500);
    }
    // console.log('AccountPDF');
    // console.log(this.state);
  };
  handleChangeAttractionChannel = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      ukraine_education: {
        ...state.ukraine_education,
        attraction_channel: val,
      },
    }));
  };
  handleChangeEnglishLevel = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      ukraine_education: {
        ...state.ukraine_education,
        english_level: val,
      },
    }));
  };
  handleChangeEducationGrup = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      ukraine_education: {
        ...state.ukraine_education,
        education_grup: val,
      },
    }));
  };
  handleChangeAbroadEducationSchool = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      abroad_education: {
        ...state.abroad_education,
        school: val,
      },
    }));
  };
  handleChangeAbroadEducationDate = (e) => {
    const date = (e.target.value || '').split('-').reverse().join('.');
    this.setState((state) => ({
      ...state,
      abroad_education: {
        ...state.abroad_education,
        date,
      },
    }));
  };
  handleChangeAbroadEducationCourse = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      abroad_education: {
        ...state.abroad_education,
        course: val,
      },
    }));
  };
  handleChangeAbroadEducationCourseProgression = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      abroad_education: {
        ...state.abroad_education,
        course_progression: val,
      },
    }));
  };
  handleChangeAbroadEducationCity = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      abroad_education: {
        ...state.abroad_education,
        city: val,
      },
    }));
  };
  handleChangeAbroadEducationDetails = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      abroad_education: {
        ...state.abroad_education,
        details: val,
      },
    }));
  };
  handleChangeManager = (e) => {
    const val = e.target ? e.target.value : e[e.length - 1];
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        manager: val,
      },
    }));
  };
  handleNexBlock = (e) => {
    e.preventDefault();
    const nex = this.state.step + 1;
    this.setState((state) => ({
      ...state,
      step: nex,
    }));
  };
  handlePrevBlock = (e) => {
    e.preventDefault();
    const prev = this.state.step - 1;
    this.setState((state) => ({
      ...state,
      step: prev,
    }));
  };

  handleChangeProgramType = async (e) => {
    const val = e.target.value;
    if (val === 0 || val === '0') {
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          programType: '',
          programName: '',
          paymant: '',
          location: '',
        },
        abroad_education: {
          school: '',
          date: '',
          course: '',
          course_progression: '',
          city: '',
          details: '',
        },
        ukraine_education: {
          attraction_channel: '',
          english_level: '',
          education_grup: '',
        },
        rateUSD: [],
        rateEUR: [],
        program_names: [],
        paymant: [],
        locations: [],
        step: 1,
        createOrderPDF: false,
        createAccountPDF: false,
      }));
    } else {
      var selected = this.state.program_types.filter((obj) => {
        return obj.id === val;
      });
      const res = await getProgramNames(val);
      const rates = await getRates();
      var rateUSD = (rates || []).filter((obj) => {
        return obj.ccy === 'USD';
      });
      var rateEUR = rates.filter((obj) => {
        return obj.ccy === 'EUR';
      });
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          programType: selected[0],
          prname: [],
          locs: [],
          locs_study: [],
        },
        program_names: res,
        rateUSD: rateUSD[0],
        rateEUR: rateEUR[0],
        abroad_education: {},
        ukraine_education: {},
        paymant: [],
        locations: [],
        step: 2,
      }));
    }
  };
  handleChangeProgramName = async (e) => {
    const val = e.target ? e.target.value : e[e.length - 1];

    var selected = this.state.program_names
      .filter((obj) => {
        return obj.title === val;
      })
      .filter((x) => x.title);

    if (!selected[0]) {
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          price_wiht_discount: '',
          amount_payable: '',
          programName: '',
          paymant: '',
          location: '',
          prname: [],
          locs: [],
          locs_study: [],
        },
        paymant: [],
        locations: [],
        step: 2,
      }));
    } else {
      const res = await getPaymant((selected[0] || {}).id);

      var select_corect_values, tmp_full_price, tmp_full_price_prepay;
      try {
        tmp_full_price = selected[0].full_price.replace(',', '').replace(' ', '');
      } catch (error) {
        tmp_full_price = selected[0].full_price;
      }
      try {
        tmp_full_price_prepay = selected[0].full_price_prepay
          .replace(',', '')
          .replace(' ', '');
      } catch (error) {
        tmp_full_price_prepay = tmp_full_price;
      }
      select_corect_values = {
        ...selected[0],
        full_price: tmp_full_price,
        full_price_prepay: tmp_full_price_prepay,
      };
      var rate;

      if (selected[0].currency === 'USD') {
        rate = this.state.rateUSD.sale;
      } else if (selected[0].currency === 'EUR') {
        rate = this.state.rateEUR.sale;
      } else {
        rate = 1;
      }
      // var tmp_amount_payable;
      // if (selected[0].tmp_amount_payable !== null) {
      //   tmp_amount_payable = selected[0].tmp_amount_payable
      //     .replace(",", "")
      //     .replace(" ", "");
      // } else {
      //   tmp_amount_payable = tmp_full_price;
      // }
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          programName: select_corect_values,
          amount_payable: tmp_full_price_prepay,
          amount_payable_in_PLN: (tmp_full_price_prepay * rate).toFixed(0),
          conversion_rate: rate,
          price_wiht_discount: tmp_full_price,
          paymant: '',
          location: '',
          locs: [],
          locs_study: [],
          prname: selected.map((n) => n.title),
        },
        paymant: res,
        locations: [],
        step: 3,
      }));
    }
  };
  handleChangePayment = async (e) => {
    const val = e.target.value;
    if (val === 0 || val === '0') {
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          paymant: '',
          location: '',
          locs: [],
          locs_study: [],
        },
        locations: [],
        step: 3,
      }));
    } else {
      var selected = this.state.paymant.filter((obj) => {
        return obj.id === val;
      });
      const res = await getLocation(val);
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          paymant: selected[0],
          location: '',
          locs: [],
          locs_study: [],
        },
        locations: res,
        step: 4,
      }));
    }
  };
  handleChangeLocation = async (e) => {
    const val = e.target ? e.target.value : e[e.length - 1];

    if (!val) {
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          location: '',
          locs: [],
        },
        step: 4,
      }));
    } else {
      let selected = this.state.locations.filter((obj) => {
        return obj.title === val;
      });
      const resRecipient = await getRecipient((selected[0] || {}).id);
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          location: selected[0] || {},
          recipient: resRecipient,
          locs: selected.map((x) => x.title),
        },
        step: 5,
      }));
    }
  };
  handleChangeLocationStudy = async (e) => {
    const val = e.target ? e.target.value : e[e.length - 1];

    if (!val) {
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          location: '',
          locs_study: [],
        },
      }));
    } else {
      let selected = this.state.locations.filter((obj) => {
        return obj.title === val;
      });
      // const resRecipient = await getRecipient((selected[0] || {}).id);
      this.setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          // location: selected[0] || {},
          // recipient: resRecipient,
          locs_study: selected.map((x) => x.title),
        },
      }));
    }
  };
  //client
  handleSetClient_same_name = (e) => {
    if (e.target.checked) {
      this.setState((state) => ({
        ...state,
        client: {
          ...state.client,
          student_name: state.client.payer_name,
        },
      }));
    }
  };
  handleChangeClient_payer_name = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        payer_name: val,
      },
    }));
  };
  handleChangeClient_student_name = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        student_name: val,
      },
    }));
  };
  handleChangeClient_birth_date = (e) => {
    var date = (e.target.value || '').split('.').reverse().join('-');
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        birth_date: date,
      },
    }));
  };
  handleChangeClient_payer_tax_ID = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        payer_tax_ID: val,
      },
    }));
  };
  handleChangeClient_link_AMO_CRM = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        link_AMO_CRM: val,
      },
    }));
  };
  handleChangeClient_address = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        address: val,
      },
    }));
  };
  handleChangeClient_now_class_course = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        now_class_course: val,
      },
    }));
  };
  handleChangeClient_passport = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      client: {
        ...state.client,
        passport: val,
      },
    }));
  };
  //
  handleChange_program_start_date = (e) => {
    const val = e.target.value;
    var tmp = val.split('-');
    var date = tmp[2] + '.' + tmp[1] + '.' + tmp[0];
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        program_start_date: date,
      },
    }));
  };
  handleChange_program_end_date = (e) => {
    const val = e.target.value;
    var tmp = val.split('-');
    var date = tmp[2] + '.' + tmp[1] + '.' + tmp[0];
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        program_end_date: date,
      },
    }));
  };
  handleChange_discount = (e) => {
    const val = e.target.value;
    const price_price = this.state.selectedValues.programName.full_price - val;
    const { amount_payable } = this.state.selectedValues;
    const set_amont_payble = amount_payable > price_price ? price_price : amount_payable;
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        discount: val,
        price_wiht_discount: price_price,
        amount_payable: set_amont_payble,
        amount_payable_in_PLN: (
          set_amont_payble * this.state.selectedValues.conversion_rate
        ).toFixed(0),
      },
    }));
  };
  handleChange_amount_payable = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        amount_payable: val,
        amount_payable_in_PLN: (val * this.state.selectedValues.conversion_rate).toFixed(
          0
        ),
      },
    }));
  };
  handleChange_amount_payable_in_PLN = (e) => {
    const val = e.target.value;
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        amount_payable_in_PLN: val,
      },
    }));
  };
  handleChange_payment_schedule = (num) => (e) => {
    const val = e.target.value;
    const shedule_payments_fields = new Set(this.state.shedule_payments_fields);

    if (this.state.selectedValues[`payment_schedule_date_${num}`]) {
      shedule_payments_fields.add(num + 1);
    }

    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        // payment_schedule: val,
        [`payment_schedule_${num}`]: val,
      },
      shedule_payments_fields: Array.from(shedule_payments_fields),
    }));
  };
  handleChange_payment_schedule_date = (num) => (e) => {
    const val = e.target.value;
    const shedule_payments_fields = new Set(this.state.shedule_payments_fields);

    if (this.state.selectedValues[`payment_schedule_date_${num}`]) {
      shedule_payments_fields.add(num + 1);
    }
    //coment
    this.setState((state) => ({
      ...state,
      selectedValues: {
        ...state.selectedValues,
        // payment_schedule_date: val,
        [`payment_schedule_date_${num}`]: val,
      },
      shedule_payments_fields: Array.from(shedule_payments_fields),
    }));
  };
  handleCreateDocument = async () => {
    // console.log(this.state, 'this.state');
    //1s send values
    const create_date_num = await sentDate({
      global_id: this.state.selectedValues.location.global_id,
      ...this.state.selectedValues,
      client: this.state.client,
      abroad_education: this.state.abroad_education,
      ukraine_education: this.state.ukraine_education,
      rateEUR: this.state.rateEUR,
      rateUSD: this.state.rateUSD,
      learn_location: this.state.learn_location,
      price_in_UAH: this.state.selectedValues.price_in_UAH || this.state.price_in_UAH,
      shedule_payments_fields: this.state.shedule_payments_fields.map((num) => ({
        summa: this.state.selectedValues[`payment_schedule_${num}`],
        date: this.state.selectedValues[`payment_schedule_date_${num}`],
      })),
    });
    // move

    // 2s set values
    const time = new Date().toLocaleString().split(',');
    this.setState((state) => ({
      ...state,
      date_create:
        ((create_date_num.date || {}).d || 0) +
        '.' +
        ((create_date_num.date || {}).m || 0) +
        '.' +
        ((create_date_num.date || {}).y || 0),
      time_create: time[1],
      unique_order_number:
        create_date_num.order_id +
        '.' +
        ((create_date_num.date || {}).d || 0) +
        ((create_date_num.date || {}).m || 0) +
        ((create_date_num.date || {}).y || 0),
      document_create: true,
    }));
  };
}

export default App;
