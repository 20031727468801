import _app from './_app';
import mainModule from './main';
import catalogsModule from './catalogs';
import adminOrdersModule from './admin-orders';
import usersModule from './users';
import adminProfile from './admin-profile';
import adminUsers from './admin-users';
import adminProgramDetails from './admin-program-details';
import adminProgramDates from './admin-program-dates';

export default [
  _app,
  usersModule,
  mainModule,
  adminProfile,
  adminUsers,
  catalogsModule,
  adminOrdersModule,
  adminProgramDetails,
  adminProgramDates,
];
