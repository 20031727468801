import React from 'react';
import styled from 'styled-components';

// Styles
const s = (select) => styled(select)`
  display: block;
  max-height: inherit;

  &[class*='ant-select-disabled']
    > [class*='ant-select-selector']
    > [class*='ant-select-selection-item'] {
    background: #e9ecef !important;
    color: inherit !important;
    border: none;
  }

  &[class*='ant-select-disabled'] > [class*='ant-select-selector'] {
    background: #e9ecef !important;
    color: inherit !important;
  }

  [class*='ant-select-selector'] {
    max-height: inherit;
  }
`;

// Component
export default (select) => {
  const Select = s(select);

  return ({ items, ...p }) => {
    return <Select {...p}>{items || p.children}</Select>;
  };
};
