import Fingerprint2 from '@fingerprintjs/fingerprintjs';

let FP = null;

export const initFingerPrint = () => {
  //console.log(Fingerprint2.getV18);
  if (window.requestIdleCallback) {
    requestIdleCallback(function () {
      Fingerprint2.getV18({}, function (result) {
        FP = result;
      });
    });
  } else {
    //console.log(Fingerprint2);
    setTimeout(function () {
      Fingerprint2.getV18({}, function (result) {
        FP = result;
      });
    }, 500);
  }
};

export const getFingerPrint = () => FP;

export default getFingerPrint;