import styled from 'styled-components';

export const marginLeft = (Button) => styled(Button)`
  margin-left: 10px;
`;

export const dateContainer = (Flex) => styled(Flex)`
  border: 1px solid #e2e2e2;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
