import { users } from '../services/request';
import { RequestError } from 'utils';

export const requests = {
  async getAllUsers(payload) {
    const response = await users.get('all', true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async addUserToServer(payload) {
    const response = await users.post('register', true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async editUserToServer(payload) {
    const response = await users.patch(`editbyadmin`, true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },

  async removeUserFromServer(_id) {
    const response = await users.delete(`delete`, true, { _id });

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
};

export default requests;
