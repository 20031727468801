import React from 'react';
import styled from 'styled-components';

const RequiredMark = styled.span`
  color: red;
`;

const Label = ({ required = false, text = '', ...props }) => {
  return (
    <label {...props}>
      {required ? <RequiredMark>* </RequiredMark> : ''}
      {text}
      {props.children}
    </label>
  );
};

const StyledLabel = styled(Label)`
  width: ${({ width }) => (width ? width : '100%')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  margin: ${({ text, required, margin }) =>
    margin ? margin : text || required ? `15px 0 0 0` : `30px 0 0 0`};
`;

export default StyledLabel;
