export const englishLevels = [
  { id: 1, title: 'Beginner' },
  { id: 2, title: 'Elementary low' },
  { id: 3, title: 'Elementary' },
  { id: 4, title: 'Elementary strong' },
  { id: 5, title: 'Pre-Intermediate low' },
  { id: 6, title: 'Pre-Intermediate' },
  { id: 7, title: 'Pre-Intermediate strong' },
  { id: 8, title: 'Intermediate low' },
  { id: 9, title: 'Intermediate' },
  { id: 10, title: 'Intermediate plus' },
  { id: 11, title: 'Upper-Intermediate' },
  { id: 12, title: 'Upper-Intermediate plus' },
  { id: 13, title: 'Advanced' },
  { id: 14, title: 'Advanced Plus' },
  { id: 15, title: 'Proficiency' },
];
