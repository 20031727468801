import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 300;
  top: 50%;
  left: 50%;
  background-color: #fff;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #c1c1c1;
  transform: translate(-50%, -50%);
  transition: 0.3s;
`;
export const Close = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 200;
`;
export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  &:active {
    transform: scale(0.98);
  }
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 1px;
    background-color: #000;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;
export const TextContainer = styled.div`
  line-height: 1.2;
  font-size: 14px;
  padding: 3px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
  & h2 {
    text-align: center;
    font-size: 15px;
    margin-bottom: 3px;
  }
`;
export const TitleSucces = styled.h2`
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
  color: green;
  & span {
    color: rgba(0, 0, 0, 0.8);
  }
`;
export const TitleError = styled.h2`
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
  color: red;
`;
