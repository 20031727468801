import React, { useMemo } from 'react';
import { connect } from 'react-redux';

// Utils
import { useHandleFields, selectDataProcessor } from 'utils';

// Components
import { Select, Label } from 'components/Fields';

// Styled
import { Title } from '../styles';

const placeholder = 'Не вибрано';

// Redux
const getDataFromStore = ({
  main: { ukraine_education, sellChannels, englishLevels, main_id },
}) => ({
  ukraine_education,
  sellChannels,
  englishLevels,
  main_id,
});
const getMethodsFromStore = ({ main: { setState } }) => ({
  setState,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(({ ukraine_education = {}, sellChannels = [], setState = () => {}, main_id = '' }) => {
  const { attraction_channel = null } = ukraine_education;

  const _sellChannels = useMemo(() => selectDataProcessor(sellChannels), [sellChannels]);

  const sellChannelDisabled = !_sellChannels.length;

  const handleFields = useHandleFields(setState);

  return (
    <>
      <Title>ДОДАТКОВА ІНФОРМАЦІЯ ПРО НАВЧАННЯ В УКРАЇНІ</Title>
      <Label required>
        <Select
          addonBefore="Канал продажу"
          placeholder={placeholder}
          options={_sellChannels.map((channel) => ({
            ...channel,
            label: channel.title,
            value: channel.title,
          }))}
          value={attraction_channel}
          disabled={main_id || sellChannelDisabled}
          onChange={handleFields('ukraine_education.attraction_channel')}
        />
      </Label>
    </>
  );
});
