import React from 'react';
import styled from 'styled-components';

const s = (checkbox) => styled(checkbox)``;

export default (checkbox) => {
  const Checkbox = s(checkbox);

  return (p) => <Checkbox {...p} />;
};
