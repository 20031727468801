import React from 'react';
import styled from 'styled-components';

const s = (carousel) => styled(carousel)`
  overflow: hidden;
  min-width: 100%;
  width: 0;
  text-align: center;
  [class*='slick-dots'] {
    position: inherit !important;
    bottom: 0 !important;
    margin-top: 10px;
    li {
      background: #ff8fa1 !important;
    }
  }

  [class='slick-arrow slick-prev'],
  [class='slick-arrow slick-next'] {
    top: 40px;
    width: 50px;
    height: 50px;
    z-index: 999;
    line-height: 50px;
    background: #000 !important;
    border-radius: 50%;
    transition: 200ms;
    &::before {
      text-align: center;
      vertical-align: center;
      transition: 200ms;
      color: #fff !important;
      font-size: 16px;
    }
  }

  [class='slick-arrow slick-prev'] {
    left: 25px;
    &::before {
      content: '❮';
    }
  }

  [class='slick-arrow slick-next'] {
    right: 25px;
    &::before {
      content: '❯';
    }
  }

  li[class*='slick-active'] {
    button {
      background: #9d3845 !important;
    }
  }
`;

export default (carousel) => {
  const Carousel = s(carousel);

  return (p) => <Carousel {...p}>{p.children}</Carousel>;
};
