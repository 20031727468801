import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { Notification } from 'ui';
import requests from './requests';
import initialState from './initialState';
import { handleRequestError, setState } from 'utils';

import Contract from '../containers/PdfCreator/contract';

export const model = {
  state: { ...initialState, loading: false },
  reducers: {
    resetState: () => initialState,
    setState,
  },

  effects: (dispatch) => ({
    async createOrder(_, rootState) {
      let order = {};
      console.log('rootState', rootState);

      try {
        const {
          selectedValues,
          client,
          main_id,
          ukraine_education,
          abroad_education,
          rateEUR,
          rateUSD,
          learn_location,
          price_in_UAH,
          shedule_payments_fields,
          programInfo,
        } = rootState?.main;
        const {
          paymant,
          price_with_discount,
          special_wishes,
          educationLocation,
          need_to_send_mail,
          attraction_channel,
          dop_school,
          dop_country,
        } = selectedValues;
        const bills = [];
        order = {
          ...selectedValues,
          // userData: userData,
          global_id: programInfo.global_id,
          full_price: programInfo.full_price,
          program_name_for_docs: programInfo.program_name_for_docs,
          currency: programInfo.currency,
          offer_agreement_link: programInfo.offer_agreement_link,
          code: programInfo.code,
          abroad_education,
          client,
          main_id,
          special_wishes,
          payment: paymant,
          price_wiht_discount: price_with_discount,
          price_in_UAH: selectedValues.price_in_UAH || price_in_UAH,
          rateEUR,
          rateUSD,
          ukraine_education,
          learn_location,
          educationLocation,
          bills,
          attraction_channel,
          fondy_link: programInfo?.fondy_link || '',
          school: dop_school,
          country: dop_country,
          shedule_payments_fields: shedule_payments_fields.map((num) => ({
            summa: selectedValues[`payment_schedule_summ_${num}`],
            date: selectedValues[`payment_schedule_date_${num}`],
          })),
        };

        order = (await requests.createOrder(order)) || order;
        if (need_to_send_mail) {
          try {
            dispatch.main.sendOrderPDFToEmail(order);
          } catch (error) {
            console.log(error.message);
          }
        }

        dispatch.main.setState({ order });
        return { succes: true, error: false };
      } catch (error) {
        Notification.error({
          message: 'Помилка створення замовлення.',
          description: error.message,
        });

        handleRequestError({
          error,
          dispatch,
          retry: dispatch.main.createOrder,
          params: order,
        });
        return { succes: false, error: true };
      }
    },
    async getCurrencies(_, rootState) {
      try {
        const exchangeRates = await requests.getCurrencies();
        const storeSelectedValues = rootState?.main?.selectedValues;
        const currency = rootState?.main?.programInfo?.currency || 1;
        const rate = exchangeRates.find((val) => val.ccy === currency)?.selectedValues;

        return dispatch.main.setState({
          exchangeRates,
          selectedValues: {
            ...storeSelectedValues,
            conversion_rate: rate,
          },
        });
      } catch (error) {
        Notification.error({
          message: 'Помилка завантаження курсу валют',
          description: error.message,
        });
      }
    },
    async setInitialPrices(_, rootState) {
      try {
        const exchangeRates = await requests.getCurrencies();
        const storeSelectedValues = rootState?.main?.selectedValues;
        const currency = rootState?.main?.programInfo?.currency || 'UAH';
        const rate = exchangeRates.find((val) => val.ccy === currency)?.sell || 1;
        const full_price = rootState?.main?.programInfo?.full_price || 0;
        const discount = storeSelectedValues?.discount || 0;
        const price_with_discount = full_price - discount;
        const amount_payable = storeSelectedValues?.amount_payable || 0;
        const amount_payable_in_PLN = (amount_payable * rate).toFixed(0);

        return dispatch.main.setState({
          exchangeRates,
          selectedValues: {
            ...storeSelectedValues,
            conversion_rate: rate,
            price_with_discount: price_with_discount,
            amount_payable_in_PLN: amount_payable_in_PLN,
          },
        });
      } catch (error) {
        Notification.error({
          message: 'Помилка конвертації ціни в необхідну валюту',
          description: error.message,
        });
      }
    },
    async getProgramTypes() {
      try {
        const program_types = await requests.getProgramTypes();

        return dispatch.main.setState({ program_types });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання типів програм',
          description: error.message,
        });
      }
    },

    async getProgramNames(programTypeId) {
      try {
        const program_names = await requests.getProgramNames(programTypeId);

        return dispatch.main.setState({ program_names });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання назв програм',
          description: error.message,
        });
      }
    },

    async getPaymentMethods({ program_type, nomenclature_1s }) {
      try {
        const paymant = await requests.getPaymentMethods({
          program_type,
          nomenclature_1s,
        });

        return dispatch.main.setState({ paymant });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання методів оплати',
          description: error.message,
        });
      }
    },

    async getPaymentLocations({ program_type, nomenclature_1s, payment_type }) {
      try {
        const locations = await requests.getPaymentLocations({
          program_type,
          nomenclature_1s,
          payment_type,
        });
        const allowAddCustomLocation = locations.includes('STUDY.UA Драгомирова 16');
        //времено:
        let customLocations = [];
        if (
          [
            'Оплата готівкою',
            'Оплата картою через термінал',
            'Оплата карткою через термінал',
            'Оплата на безготівковий рахунок',
          ].includes(payment_type) &&
          allowAddCustomLocation
        ) {
          customLocations = ['Выставка 1', 'Выставка 2', 'Выставка 3'];
        }
        //
        //  return dispatch.main.setState({ locations });
        return dispatch.main.setState({ locations: customLocations?.concat(locations) });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання локацій',
          description: error.message,
        });
      }
    },
    // getEducationLocations
    async getEducationLocations({ code }) {
      try {
        const educationLocations = await requests.getEducationLocations({ code });
        return dispatch.main.setState({ educationLocations });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання локацій навчання',
          description: error.message,
        });
      }
    },

    async getRecipient({ program_type, nomenclature_1s, payment_type, location }) {
      let newLoaction = location;
      if (['Выставка 1', 'Выставка 2', 'Выставка 3'].includes(location)) {
        newLoaction = 'STUDY.UA Драгомирова 16';
      }
      try {
        const { recipient = {}, programInfo = {} } = await requests.getRecipient({
          program_type,
          nomenclature_1s,
          payment_type,
          location: newLoaction,
        });
        let newRecipient = recipient;
        if (
          location === 'Выставка 1' &&
          [
            'Оплата готівкою',
            'Оплата картою через термінал',
            'Оплата карткою через термінал',
          ].includes(payment_type)
        ) {
          newRecipient = {
            bank: 'АТ КБ "ПРИВАТБАНК"',
            details: 'Є платником єдиного податку на 2-ій групі',
            director: '',
            edrpou: '3028314021',
            iban: 'UA473052990000026002036804873',
            name: 'ФОП Бойко Ганна Олегівна',
            place: 'Україна',
            signature_link: '',
            type: 'ФОП',
            _id: 'vistavka_1',
          };
        } else if (
          location === 'Выставка 2' &&
          [
            'Оплата готівкою',
            'Оплата картою через термінал',
            'Оплата карткою через термінал',
          ].includes(payment_type)
        ) {
          newRecipient = {
            bank: 'АТ КБ "ПРИВАТБАНК"',
            details: 'Є платником єдиного податку на 2-ій групі',
            director: '',
            edrpou: '3028314021',
            iban: 'UA473052990000026002036804873',
            name: 'ФОП Бойко Ганна Олегівна',
            place: 'Україна',
            signature_link: '',
            type: 'ФОП',
            _id: 'vistavka_2',
          };
        } else if (
          location === 'Выставка 3' &&
          [
            'Оплата готівкою',
            'Оплата картою через термінал',
            'Оплата карткою через термінал',
          ].includes(payment_type)
        ) {
          newRecipient = {
            bank: 'АТ КБ "ПРИВАТБАНК"',
            details: 'Є платником єдиного податку на 2-ій групі',
            director: '',
            edrpou: '3028314021',
            iban: 'UA473052990000026002036804873',
            name: 'ФОП Бойко Ганна Олегівна',
            place: 'Україна',
            signature_link: '',
            type: 'ФОП',
            _id: 'vistavka_3',
          };
        }
        const amount_payable = +programInfo?.full_price_prepay || 0;
        return dispatch.main.setState((state) => ({
          selectedValues: {
            ...state.selectedValues,
            amount_payable,
            recipient: newRecipient,
          },
          programInfo: programInfo,
        }));
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання виконавців',
          description: error.message,
        });
      }
    },

    async getManagers() {
      try {
        const response = (await requests.getManagers()) || [];

        const managers = response.map((manager = {}) => {
          const { firstName = '', lastName = '', _id = '' } = manager;

          return {
            ...manager,
            name: `${firstName} ${lastName}`,
            id: _id,
          };
        });

        return dispatch.main.setState({ managers });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання менеджерів',
          description: error.message,
        });

        handleRequestError({ error, dispatch, retry: dispatch.main.getManagers });
      }
    },

    async changePriceWithDiscount({ val, handleValue, handleWithDiscount }, rootState) {
      try {
        const full_price = rootState?.main?.programInfo?.full_price || 800;
        if (typeof val === 'string') {
          val = val.replace(/\D+/g, '');
          if (val.length > 0) val = +val;
        }
        handleValue(val);
        handleWithDiscount(full_price - val);
      } catch (error) {
        Notification.error({
          message: 'Помилка зміни ціни з врахуванням знижки',
          description: error.message,
        });
      }
    },
    async changeAmountPaybelAndConversion(
      { val, handleValue, handleConversion },
      rootState
    ) {
      try {
        const conversion_rate = rootState?.main?.selectedValues?.conversion_rate || 1;

        if (typeof val === 'string') {
          val = val.replace(/\D+/g, '');
          if (val.length > 0) val = +val;
        }

        handleValue(val);
        handleConversion((val * conversion_rate).toFixed(0));
      } catch (error) {
        Notification.error({
          message: 'Model changeAmountPaybelAndConversion error',
          description: error.message,
        });
      }
    },
    async sendOrderPDFToEmail(order) {
      try {
        const element = <Contract orderValues={order} />;
        const myPdf = pdf([]);

        myPdf.updateContainer(element);
        const fileName = `Договір №${order?.order_id}-${order?.createDate?.d}${order?.createDate?.m}${order?.createDate?.y}`;
        const subject = `${order?.location} // ${order?.manager} // ${fileName}`;
        const blob = await myPdf.toBlob();
        const file = new File([blob], `${fileName}.pdf`, {
          lastModified: new Date().getTime(),
        });
        dispatch.main.setState({
          loading: true,
        });
        await requests.sendOrderPDFToEmail([file, order.special_wishes, subject]);
        dispatch.main.setState({
          mailPDFSendError: false,
          mailPDFSendSuccess: true,
          loading: false,
        });
      } catch (error) {
        Notification.error({
          message: 'Помилка відправки поштового повідомлення в документом',
          description: error.message,
        });
        dispatch.main.setState({
          mailPDFSendError: true,
          mailPDFSendSuccess: false,
          loading: false,
        });
      }
    },
    // async order1CSend(order) {
    //   try {
    //     const res = await requests.order_update_1c(order);
    //     return res;
    //   } catch (error) {
    //     Notification.error({
    //       message: 'Помилка синхронізації з 1С',
    //       description: error.message,
    //     });
    //     return error;
    //   }
    // },
    // async bill1CSend(bill) {
    //   try {
    //     const res = await requests.bill_update_1c(bill);
    //     return res;
    //   } catch (error) {
    //     Notification.error({
    //       message: 'Помилка синхронізації з 1С',
    //       description: error.message,
    //     });
    //     return error;
    //   }
    // },
    // async all1CSend() {
    //   try {
    //     const res = await requests.all_update_1c();
    //     return res;
    //   } catch (error) {
    //     Notification.error({
    //       message: 'Помилка синхронізації з 1С',
    //       description: error.message,
    //     });
    //     return error;
    //   }
    // },
    async getCountryList() {
      try {
        const country_list = (await requests.getCountryList()) || [];

        return dispatch.main.setState({ country_list });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання списку країн',
          description: error.message,
        });

        handleRequestError({ error, dispatch, retry: dispatch.main.getCountryList });
      }
    },
    async getSchoolList() {
      try {
        const school_list = (await requests.getSchoolList()) || [];

        return dispatch.main.setState({ school_list });
      } catch (error) {
        Notification.error({
          message: 'Помилка отримання списку шкіл',
          description: error.message,
        });

        handleRequestError({ error, dispatch, retry: dispatch.main.getSchoolList });
      }
    },
    async searchCleintPayer(payload) {
      try {
        const search_list = (await requests.searchCleintPayer(payload)) || [];
        return search_list;
      } catch (error) {
        Notification.error({
          message: 'Помилка пошуку клієнта',
          description: error.message,
        });
      }
    },
    async searchCleintStudent(payload) {
      try {
        return (await requests.searchCleintStudent(payload)) || [];
      } catch (error) {
        Notification.error({
          message: 'Помилка пошуку клієнта',
          description: error.message,
        });
      }
    },
  }),
};
