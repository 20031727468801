import React, { useState } from 'react';
import moment from 'moment';
import { components as _components } from './EditProgramDatesModalComponents';
import { connect } from 'react-redux';

// Components
import { Input, Text, Select, InputPassword, DatePicker } from 'ui';

// Styled
import { ModalContent } from '../ProgramDatesTable/styles';

const ComponentsList = {
  input: Input,
  select: Select,
  inputPassword: InputPassword,
  date: DatePicker,
};

// Redux
const getDataFromStore = ({
  adminProgramDates: { modalData, currentRecord },
  users: { roles },
}) => ({
  roles,
  modalData,
  currentRecord,
});
const getMethodsFromStore = ({ adminProgramDates: { setModalData } }) => ({
  setModalData,
});

/**
 * Component
 */
export const EditProgramDatesModal = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ modalData = {}, roles = [], setModalData = () => {}, currentRecord = {} }) => {
  const [localData, setLocalData] = useState(modalData);

  const handleFields = (key) => (e) => {
    if (key === 'startDate' || key === 'finishDate') {
      setLocalData({
        ...localData,
        [key]: e?._isValid ? moment(e)?.format('YYYY-MM-DD') : '',
      });
    } else {
      setLocalData({ ...localData, [key]: e?.target ? e?.target?.value : e });
    }
  };
  const components = _components({ handleFields });
  const selectData = {
    // clients: currentRecord.clients || [],
    roles,
  };

  return (
    <ModalContent onMouseLeave={() => setModalData({ ...modalData, ...localData })}>
      {components.map((comp, index) => {
        const Field = ComponentsList[comp.type];
        const additionalProps = {};

        if (comp.isHasItems) {
          additionalProps.items = (selectData[comp.listName] || []).map((fld, k) => (
            <option key={k} value={fld}>
              {fld}
            </option>
          ));
        }

        const { placeholder = '', type = '' } = comp;

        return (
          <div>
            <Text>{placeholder ? `${placeholder}:` : ''}</Text>
            {type === 'date' ? (
              <Field
                key={index}
                className={'Field'}
                {...comp.behaviours}
                placeholder={comp.placeholder}
                format={'YYYY-MM-DD'}
                value={
                  localData[comp.value]
                    ? moment(localData[comp.value], 'YYYY-MM-DD')
                    : currentRecord[comp.defaultValue]
                    ? moment(currentRecord[comp.defaultValue], 'YYYY-MM-DD')
                    : ''
                }
                {...additionalProps}
              />
            ) : (
              <Field
                key={index}
                className={'Field'}
                {...comp.behaviours}
                placeholder={comp.placeholder}
                value={localData[comp.value]}
                defaultValue={
                  modalData[comp.defaultValue] || currentRecord[comp.defaultValue]
                }
                {...additionalProps}
              />
            )}
          </div>
        );
      })}
    </ModalContent>
  );
});
