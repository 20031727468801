export const components = ({ handleFields = () => {} }) => [
  {
    type: 'input',
    value: 'firstName',
    behaviours: { onChange: handleFields('firstName') },
    defaultValue: 'firstName',
    placeholder: "Ім'я",
    isHasItems: false,
  },
  {
    type: 'input',
    value: 'lastName',
    behaviours: { onChange: handleFields('lastName') },
    defaultValue: 'lastName',
    placeholder: 'Прізвище',
    isHasItems: false,
  },
  {
    type: 'input',
    value: 'email',
    behaviours: { onChange: handleFields('email') },
    defaultValue: 'email',
    placeholder: 'Пошта',
    isHasItems: false,
  },
  {
    type: 'select',
    value: 'role',
    behaviours: { onChange: handleFields('role') },
    defaultValue: 'role',
    placeholder: 'Роль',
    listName: 'roles',
    isHasItems: true,
  },
  {
    type: 'inputPassword',
    value: 'password',
    behaviours: { onChange: handleFields('password') },
    // defaultValue: 'password',
    placeholder: 'Пароль',
    isHasItems: false,
  },
  {
    type: 'checkBox',
    // value: 'blocked',
    checked: 'blocked',
    behaviours: { onChange: handleFields('blocked') },
    defaultChecked: 'blocked',
    placeholder: 'Блокувати',
    isHasItems: false,
  },
];
