import React from 'react';
import styled from 'styled-components';

const s = (inputNumber) => styled(inputNumber)``;

export default (inputNumber) => {
  const InputNumber = s(inputNumber);

  return (p) => <InputNumber {...p} />;
};
